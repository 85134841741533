import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class MemberActivityTable extends DashboardChartBase {

  showMessage: boolean;
  memberActivities: Array<any>;

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(startDate: any, endDate: any, eventId: number) {
    this.reload(startDate, endDate, eventId);
  }

  reload(startDate: any, endDate: any, eventId: number) {
    this.showLoading();
    this._dashboardService
      .getMemberActivity(startDate, endDate, eventId)
      .subscribe(result => {
        this.memberActivities = result.memberActivities;
        this.showMessage = true;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-member-activity',
  templateUrl: './widget-member-activity.component.html',
  styleUrls: ['./widget-member-activity.component.css']
})
export class WidgetMemberActivityComponent extends WidgetComponentBase implements OnInit {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterEvent: number = undefined;
  defaultpic = this.appRootUrl() + '/assets/common/images/default-profile-picture.png'

  memberActivityTable: MemberActivityTable;

  constructor(injector: Injector,
    private _dashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.memberActivityTable = new MemberActivityTable(this._dashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subEventFilter();
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.memberActivityTable.init(this.selectedDateRange[0], this.selectedDateRange[1], this.filterEvent);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.reloadTab);
  }

  onEventFilterChange = (eventId: number) => {
    this.filterEvent = eventId;
    this.runDelayed(this.reloadTab);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subEventFilter() {
    abp.event.on('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  unSubEventFilter() {
    abp.event.off('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubEventFilter();
  }
}
