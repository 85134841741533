import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class DashboardTopStats extends DashboardChartBase {

  totalProfit = 0; totalProfitCounter = 0;  
  totalProfitChange = 0; totalProfitChangeCounter = 0;
  
  init(totalProfit, totalProfitChange) {
    this.totalProfit = totalProfit;
    this.totalProfitChange = totalProfitChange;
    this.hideLoading();
  }
}

@Component({
  selector: 'app-widget-financial-total-profit',
  templateUrl: './widget-financial-total-profit.component.html',
  styleUrls: ['./widget-financial-total-profit.component.css']
})
export class WidgetFinancialTotalProfitComponent extends WidgetComponentBase implements OnInit {

  dashboardTopStats: DashboardTopStats;
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  
  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.dashboardTopStats = new DashboardTopStats();
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.runDelayed(this.loadTopStatsData);
  }
  
  loadTopStatsData = () => {
    this._tenantDashboardServiceProxy.getGeneralTotalProfit(this.selectedDateRange[0], this.selectedDateRange[1], undefined).subscribe((data) => {
      this.dashboardTopStats.init(data.total, data.totalChange);
    });
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.loadTopStatsData);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }
}
