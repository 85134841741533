import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NameValueDto, PagedResultDtoOfNameValueDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { LazyLoadEvent } from 'primeng/components/common/lazyloadevent';
import { Observable } from 'rxjs';
import { KtDatatableBase, KtDatatableBaseParams, KtDatatableComponent } from '../kt-datatable/kt-datatable/kt-datatable.component';
import * as _ from 'lodash';
declare var $: any;

export class KtDatatableUserParams extends KtDatatableBaseParams {
    Filter: any;
}

export interface ICommonLookupModalOptions {
    title?: string;
    isFilterEnabled?: boolean;
    dataSource: (skipCount: number, maxResultCount: number, filter: string, tenantId?: number) => Observable<PagedResultDtoOfNameValueDto>;
    canSelect?: (item: NameValueDto) => boolean | Observable<boolean>;
    loadOnStartup?: boolean;
    pageSize?: number;
}

//For more modal options http://valor-software.com/ngx-bootstrap/#/modals#modal-directive

@Component({
    selector: 'commonLookupModal',
    templateUrl: './common-lookup-modal.component.html'
})
export class CommonLookupModalComponent extends AppComponentBase {

    static defaultOptions: ICommonLookupModalOptions = {
        dataSource: undefined,
        canSelect: () => true,
        loadOnStartup: true,
        isFilterEnabled: true,
        pageSize: AppConsts.grid.defaultPageSize
    };

    @Output() itemSelected: EventEmitter<NameValueDto> = new EventEmitter<NameValueDto>();

    @ViewChild('ktDatatable', { static: false }) ktDatatable: KtDatatableComponent;
    @ViewChild('modal', {static: true}) modal: ModalDirective;
    
    options: ICommonLookupModalOptions = _.merge({});

    isShown = false;
    isInitialized = false;
    filterText = '';
    tenantId?: number;
    onlyAdminMembers = !!this.appSession.tenant && !this.isGranted('Pages.Administration.Tenant.Members');

    ktDatatableOptions: KtDatatableBase;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadKtDatatableOptions();
    }

    ngAfterViewInit() {

        var self = this;
    
        setTimeout(() => {
    
            if (this.ktDatatable && this.ktDatatable.ktDatatable) {
    
            this.ktDatatable.ktDatatable.on('click', '.user-select', function () {
              let index = $(this).data('index');
              let record = self.ktDatatable.ktDatatable.originalDataSet[index];
              self.selectItem(record);
            });        
            }
            else {
                this.ngAfterViewInit();
            }
    
        }, 200);
    }

    configure(options: ICommonLookupModalOptions): void {
        this.options = _.merge({}, CommonLookupModalComponent.defaultOptions, { title: this.l('SelectAnItem') }, options);
    }

    show(): void {
        if (!this.options) {
            throw Error('Should call CommonLookupModalComponent.configure once before CommonLookupModalComponent.show!');
        }
        
        this.modal.show();
    }

    refreshTable(): void {
        this.ktDatatable.ktDatatable.reload();
    }

    close(): void {
        this.modal.hide();
        this.closeModal();
    }

    shown(): void {
        this.isShown = true;
        this.getRecordsIfNeeds(null);
        this.ktDatatable.ktDatatable.reload();
    }

    getRecordsIfNeeds(event?: LazyLoadEvent): void {
        if (!this.isShown) {
            return;
        }

        if (!this.options.loadOnStartup && !this.isInitialized) {
            return;
        }

        this.getRecords();
        this.isInitialized = true;
    }

    getRecords(): void {
        this.ktDatatable.ktDatatable.reload();
    }

    selectItem(item: NameValueDto) {
        const boolOrPromise = this.options.canSelect(item);
        if (!boolOrPromise) {
            return;
        }

        if (boolOrPromise === true) {
            this.itemSelected.emit(item);
            this.close();
            return;
        }

        //assume as observable
        (boolOrPromise as Observable<boolean>)
            .subscribe(result => {
                if (result) {
                    this.itemSelected.emit(item);
                    this.close();
                }
            });
    }

    loadKtDatatableOptions() {

        var self = this;
    
        let options = new KtDatatableBase();
        options.IdName = 'kt_datatable_user_select';
        options.Service = 'CommonLookup/GetTenantUsers';
        options.Params = new KtDatatableUserParams();
        options.Params.Filter = function () {
            return self.filterText;
        };
        options.Params.TenantId = function() {
            return self.tenantId;
        },
        options.Params.OnlyAdminMembers = function() {
            return self.onlyAdminMembers;
        },
        options.Columns = [
            {
                field: "name",
                title: self.l("Name"),
                sortable: true,
            },
            {
                field: '',
                title: self.l("Select"),
                sortable: false,
                width: 70,
                autoHide: false,
                overflow: 'visible',
                textAlign: 'center',
                template: function (record, index) {    
                    let template = `
                        <a href="javascript:;" class="btn btn-hover-brand btn-icon btn-pill user-select" data-index="${index}" title="${self.l('Select')}">
                            <i class="la la-chevron-circle-right""></i>
                        </a>`;              
                
                    return template;
                }
            }
        ];
    
        self.ktDatatableOptions = options;
    }
}
