import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { RegistrationSituation, TenantDashboardServiceProxy, PaymentStatus, OrdersServiceProxy, GetReceiptDto } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';

class MyRegistrationsList extends DashboardChartBase {

  registrations: Array<any>;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getMyRegistrations(this.userLoggedId)
      .subscribe(result => {
        this.registrations = result.registrations;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-my-registrations',
  templateUrl: './widget-my-registrations.component.html',
  styleUrls: ['./widget-my-registrations.component.css']
})
export class WidgetMyRegistrationsComponent extends WidgetComponentBase implements OnInit {

  myRegistrationsList: MyRegistrationsList;
  registrationSituation = RegistrationSituation;
  paymentStatus = PaymentStatus;

  constructor(injector: Injector,
    private _router: Router,
    private _ordersServiceProxy: OrdersServiceProxy,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.myRegistrationsList = new MyRegistrationsList(this._dashboardService);
  }

  ngOnInit() {
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.myRegistrationsList.init();
  }

  certificate(registration) {
    abp.event.trigger('app.show.certificate', registration);
  }

  goToAllRegistrations() {
    this._router.navigate(['app/main/my-registrations']);
  }

  openPaymentLink(registration): void {
    this._router.navigate(['app/main/registration-payment/' + registration.eventId]);
  }

  generateReceipt(registrationId): void {

    let input = new GetReceiptDto();
    input.registrationId = registrationId;

    this._ordersServiceProxy.generateReceipt(input)
        .subscribe(result => {
            window.open(AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + result.file.fileName)
        });
  }
}
