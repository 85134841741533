import { Component, OnInit, Input, Injector, ViewChild } from '@angular/core';
import { CorreiosService } from '@app/shared/common/correios/correios.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressType } from '@shared/service-proxies/service-proxies';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'addresses',
  templateUrl: './addresses.component.html',
  exportAs: 'addressesComponent'
})
export class AddressesComponent extends AppComponentBase implements OnInit {

  addressType = AddressType;

  @Input("addresses") _addresses: any[];
  @Input("addressDto") AddressDto;
  @ViewChild('addressesForm', { static: true }) public addressesForm: NgForm;

  public addresses: any[] = [];
  public brazilStates: any[] = CorreiosService.loadBrazilStates();
  public countries: any[] = CorreiosService.loadCountries();
  public citiesFromState: any[] = [];
  
  countName = 0;

  constructor(
    private _correiosService: CorreiosService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.addresses = this._addresses;
    this.loadCitiesFromState();
    this.setUppercaseAddresses();
  }

  setUppercaseAddresses() {

    if (this.addresses) {
      this.addresses.forEach(address => {
        address.city = address.city ? address.city.toUpperCase() : address.city;
        address.complement = address.complement ? address.complement.toUpperCase() : address.complement;
        address.district = address.district? address.district.toUpperCase() : address.district;
        address.logradour = address.logradour ? address.logradour.toUpperCase() : address.logradour;
        address.stateAbbreviation = address.stateAbbreviation ? address.stateAbbreviation.toUpperCase() : address.stateAbbreviation;
        address.stateName = address.stateName ? address.stateName.toUpperCase() : address.stateName;        
      });      
    }
  }

  addAddress(): void {

    let address = this.newAddress();
    address.type = AddressType.Home; 
    this.addresses.push(address);
  };

  deleteAddress(index: number): void {
    this.addresses.splice(index, 1);
    this.citiesFromState.splice(index, 1);

    if (this.addresses.length == 1) {
      this.addresses[0].isMailing = true;
    }

    this.addressesForm.form.markAsDirty();
  };

  loadCitiesFromState() {

    this.addresses.forEach((address, i) => {

      if (this.isBrasil(i)) {
        this._correiosService.loadCities(this.addresses[i].stateAbbreviation)
          .pipe(map(cities => {

            cities.forEach(city => {
              city.Municipio = city.Municipio.toUpperCase();
            });

            this.citiesFromState.push(cities);
          }))
          .subscribe();
      }
      else
        this.citiesFromState.push([]);
    });
  };

  loadCities(index: number) {

    let stateAbbreviation = this.addresses[index].stateAbbreviation;

    if (this.isBrasil(index)) {
      this._correiosService.loadCities(stateAbbreviation)
        .pipe(map(cities => {

          cities.forEach(city => {
            city.Municipio = city.Municipio.toUpperCase();
          });

          this.citiesFromState[index] = cities;
          this.setValueStateName(index);
        }))
        .subscribe();
    }
    else
      this.addresses[index].stateName = stateAbbreviation;
  }

  clearStateAndCity(index: number) {
    this.addresses[index].stateName = "";
    this.addresses[index].stateAbbreviation = "";
    this.addresses[index].city = "";
    this.addresses[index].zipCode = "";
  }

  setValueStateName(index: number) {

    this.brazilStates.forEach(state => {

      if (state.abbreviation == this.addresses[index].stateAbbreviation) {
        this.addresses[index].stateName = state.name;
      }
    });
  }

  // Only a address can be isMailing
  setOneIsMailingAddress(index: number) {

    if (this.addresses[index].isMailing) {

      this.addresses.forEach((address, i) => {
        if (i != index)
          address.isMailing = false;
      });
    }
  };

  searchZipCode(index: number) {

    let zipCode = this.addresses[index].zipCode;

    zipCode = zipCode ? zipCode.replace('_', '') : '';

    if (zipCode && zipCode.length >= 8) {

      this._correiosService.searchByPostalCode(zipCode).subscribe(result => {

            let data = result[0];

            if (!!data) {
              this.addresses[index].city = data.Municipio.toUpperCase();
              this.addresses[index].complement = data.Complemento.toUpperCase();
              this.addresses[index].district = data.Bairro.toUpperCase();
              this.addresses[index].logradour = data.Logradouro.toUpperCase();
              this.addresses[index].stateAbbreviation = data.Estado.toUpperCase();
              this.addresses[index].stateName = data.Estado.toUpperCase();
              this.addresses[index].countryName = 'Brasil';
            }

            if (this.addresses.length == 1) {
              this.addresses[index].isMailing = true;
            }

            this.loadCities(index);

      });
    }
  };

  isBrasil(index: number): boolean {
    return this.addresses[index].countryName == 'Brasil';
  }

  newAddress() {

    let address = new this.AddressDto();
    address.countryName = 'Brasil';
    address.id = this.countName--;
    address.isMailing = false;

    if (this.addresses.length == 0) {
      address.type = AddressType.Home;
      address.isMailing = true;
    }

    return address;
  }
}
