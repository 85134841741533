import { LinkedAccountService } from './../linked-account.service';
import { AfterViewInit, Component, EventEmitter, Injector, OnInit, Input, Output, ViewChild, ViewEncapsulation, NgZone, HostBinding, ElementRef } from '@angular/core';
import { CommonLookupModalComponent } from '@app/shared/common/lookup/common-lookup-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FileUpload } from 'primeng/fileupload';

import {
    ChatSide,
    CommonLookupServiceProxy,
    FriendshipState,
    UserLoginInfoDto,
    ChatMessageReadState,
    ProfileServiceProxy,
    LinkedUserDto
} from '@shared/service-proxies/service-proxies';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChatFriendDto } from '../chat/ChatFriendDto';
import { AbpSessionService } from 'abp-ng2-module/dist/src/session/abp-session.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AbpMultiTenancyService } from 'abp-ng2-module/dist/src/multi-tenancy/abp-multi-tenancy.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    templateUrl: './profile-bar-component.html',
    selector: 'profile-bar',
    styleUrls: ['./profile-bar-component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileBarComponent extends AppComponentBase implements OnInit, AfterViewInit {

    @Output() onProgress: EventEmitter<any> = new EventEmitter();
    @Input() userLookupModal: CommonLookupModalComponent;

    @HostBinding('id') id = 'kt_quick_sidebar';
    @HostBinding('class')
    classes = 'kt-quick-panel';
    @HostBinding('attr.mQuickSidebarOffcanvas')

    @HostBinding('style.overflow') styleOverflow: any = 'hidden';

    mQuickSidebarOffcanvas: any;

    @ViewChild('ChatMessage', {static: true}) chatMessageInput: ElementRef;
    @ViewChild('chatScrollBar', {static: true}) chatScrollBar;

    @ViewChild('chatImageUpload', {static: true}) chatImageUpload: FileUpload;
    @ViewChild('chatFileUpload', {static: true}) chatFileUpload: FileUpload;

    friendDtoState: typeof FriendshipState = FriendshipState;

    friends: ChatFriendDto[];
    currentUser: UserLoginInfoDto = this.appSession.user;
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    chatMessage = '';

    isImpersonatedLogin = false;
    tenantToTenantChatAllowed = false;
    tenantToHostChatAllowed = false;
    interTenantChatAllowed = false;
    sendingMessage = false;
    loadingPreviousUserMessages = false;
    userNameFilter = '';
    serverClientTimeDifference = 0;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    appChatSide: typeof ChatSide = ChatSide;
    appChatMessageReadState: typeof ChatMessageReadState = ChatMessageReadState;

    shownLoginName = '';
    tenancyName = '';
    userName = '';
    nameUser = '';
    emailUser = '';

    recentlyLinkedUsers: LinkedUserDto[];    

    _isOpen: boolean;
    set isOpen(newValue: boolean) {
        if (newValue === this._isOpen) {
            return;
        }

        this._isOpen = newValue;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    _pinned = false;
    set pinned(newValue: boolean) {
        if (newValue === this._pinned) {
            return;
        }

        this._pinned = newValue;
        this._localStorageService.setItem('app.userprofile.pinned', newValue);
    }
    get pinned(): boolean {
        return this._pinned;
    }

    constructor(
        private el: ElementRef,
        injector: Injector,
        private _authService: AppAuthService,
        private _abpSessionService: AbpSessionService,
        private _commonLookupService: CommonLookupServiceProxy,
        private _localStorageService: LocalStorageService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        public _zone: NgZone) {
        super(injector);
    }

    ngOnInit(): void {
        this.init();
        this.setCurrentLoginInformations();
        this.registerToEvents();
        this.getProfilePicture();
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });
    }

    reversePinned(): void {
        this.pinned = !this.pinned;
    }

    ngAfterViewInit(): void {
        this.mQuickSidebarOffcanvas = new KTOffcanvas(this.el.nativeElement, {
            overlay: true,
            baseClass: 'kt-quick-panel',
            closeBy: 'kt_quick_sidebar_close_profile',
            toggleBy: 'kt_quick_sidebar_toggle_profile'
        });

        this.mQuickSidebarOffcanvas.events.push({
            name: 'afterHide',
            handler: () => {
                if (this._pinned) {
                    this.mQuickSidebarOffcanvas.show();
                } else {
                    this.isOpen = false;
                }
            }
        }, {
                name: 'afterShow',
                handler: () => {
                    this.isOpen = true;
                }
            });

        this.userLookupModal.configure({
            title: this.l('SelectAUser'),
            dataSource: (skipCount: number, maxResultCount: number, filter: string, tenantId?: number) => {                
                return this._commonLookupService.getTenantUsers(filter, false, tenantId, undefined, maxResultCount, skipCount);
            }
        });
    }

    showChatPanel(): void {
        document.body.className += ' kt-quick-panel--on';
        document.getElementById('kt_quick_sidebar').className += ' kt-quick-panel--on';
    }

    init(): void {
        
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
        this.nameUser = this.appSession.user.name;
        this.emailUser = this.appSession.user.emailAddress;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    showLinkedAccounts(): void {
        abp.event.trigger('app.show.linkedAccountsModal');
    }

    showLoginAttempts(): void {
        abp.event.trigger('app.show.loginAttemptsModal');
    }
    
    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }
    
    changeMySettings(): void {
        abp.event.trigger('app.show.mySettingsModal');
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }

    logout(): void {
        this._authService.logout();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }
}
