import { Component, OnInit, Injector } from '@angular/core';
import { EventsServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-filter-event',
  templateUrl: './filter-event.component.html',
  styleUrls: ['./filter-event.component.css']
})
export class FilterEventComponent  extends AppComponentBase {

  events = [];
  filterEvent = undefined;

  constructor(injector: Injector, private _eventService: EventsServiceProxy) {
    super(injector);
  }

  ngOnInit() {
  }

  filterEvents(event: any): void {
    this._eventService.getEventsForSelect(
            event.query,
            undefined,
            undefined, 
            undefined, 
            undefined
        ).subscribe(result => {
        this.events = result.items;
    });
  }

  onSelect(): void {
    abp.event.trigger('app.dashboardFilters.eventFilter.onEventChange', this.filterEvent.id);
  }

}
