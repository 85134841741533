import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CurrentProfileEditDto, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'termModal',
    templateUrl: './term-modal.component.html'
})
export class TermModalComponent extends AppComponentBase {

    @ViewChild('profileModal', {static: true}) modal: ModalDirective;
    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
    
    textTerm = undefined;
    contractTerms = false;

    public active = false;    
    public saving = false;
    
    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy
    ) {
        super(injector);
    }

    show(textTerm: string, contractTerms: boolean): void {        
        this.textTerm = textTerm;
        this.contractTerms = contractTerms;
        this.active = true;
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
        this.modalClose.emit({contractTerms: this.contractTerms});
        this.closeModal();
    }

    save(): void {
        this.close();
    }
}
