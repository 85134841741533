import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { CreateOrEditDocumentDto, CreateOrEditGuestDto, GuestsServiceProxy, MembersServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'viewScheduleModal',
    templateUrl: './view-schedule-modal.component.html'
})
export class ViewScheduleModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() goConflict: EventEmitter<any> = new EventEmitter<any>();
    
    active = false;
    saving = false;

    guestId = undefined;
    guest: CreateOrEditGuestDto = undefined;
    calendarActivities = [];

    constructor(
        injector: Injector,
        private _router: Router,
        private _guestsServiceProxy: GuestsServiceProxy,
        private _membersServiceProxy: MembersServiceProxy,
        private _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    show(guestId: number): void {
        this.guestId = guestId;
        this.loadSchedule();
    }

    loadSchedule() {
        this._guestsServiceProxy.getGuestSchedule(this.guestId).subscribe(result => {
            if (result.guest) {                
                this.calendarActivities = result.calendarActivities;
                this.guest = result.guest;

                if (result.guest.userId && !result.guest.user)
                    this.guest.name = this.l('UserDeleted');

                this.active = true;
                this.modal.show();
            }
        });
    }      

    close(): void {
        this.active = false;
        this.guest = undefined;
        this.calendarActivities = [];
        this.modal.hide();
        this.closeModal();
    }

    downloadFile(document: CreateOrEditDocumentDto): void {

        this.saving = true;
                
        this._membersServiceProxy
            .getDocumentFile(document.documentId, document.fileExtension, document.id)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(result => {
              this._fileDownloadService.downloadTempFile(result);            
        });
    }

    goToConflict(activityId: number) {
        this._router.navigate([`app/main/activities/edit/${activityId}`]);
        this.goConflict.emit();
        setTimeout(() => {
            this.close();    
        }, 100);        
    }
}
