import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { GetReceiptDto, OrderSituation, OrdersServiceProxy, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';

class MyOrdersList extends DashboardChartBase {

  orders: Array<any>;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getMyOrders(this.userLoggedId)
      .subscribe(result => {
        this.orders = result.orders;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-my-orders',
  templateUrl: './widget-my-orders.component.html',
  styleUrls: ['./widget-my-orders.component.css']
})
export class WidgetMyOrdersComponent extends WidgetComponentBase implements OnInit {

  myOrdersList: MyOrdersList;
  orderSituation = OrderSituation;

  constructor(injector: Injector,
    private _router: Router,
    private _ordersServiceProxy: OrdersServiceProxy,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.myOrdersList = new MyOrdersList(this._dashboardService);
  }

  ngOnInit() {
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.myOrdersList.init();
  }

  goToAllOrders() {
    this._router.navigate(['app/main/my-orders']);
  }

  openPaymentLink(record): void {
    this._router.navigate(['app/main/order-payment/' + record.id]);
  }

  generateReceipt(orderId): void {

    let input = new GetReceiptDto();
    input.orderId = orderId;

    this._ordersServiceProxy.generateReceipt(input)
        .subscribe(result => {
            window.open(AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + result.file.fileName)
        });
  }
}
