import { NgForm } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { OnInit, ViewChild, Injector, Component, Input } from "@angular/core";
import { AnnuitySettingCategoryDeadlineDto, AnnuitySettingCategoryDto, CategoriesServiceProxy, CategoryListDto, CreateOrEditAnnuitySettingDto } from "@shared/service-proxies/service-proxies";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as moment from 'moment';
import * as _ from "lodash";
declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'annuitySettingsCategories',
    templateUrl: './annuity-settings-categories.html',
    exportAs: 'annuitySettingsCategoriesComponent'
  })
  export class AnnuitySettingsCategoriesComponent extends AppComponentBase implements OnInit {
    
    @Input() annuitySetting: CreateOrEditAnnuitySettingDto;
    @Input() hasReadmission: boolean;
    @Input() hasCreditCardSelected: boolean;
    @ViewChild('annuitySettingsCategoriesForm', { static: true }) annuitySettingsCategoriesForm: NgForm;
  
    countName = 0;
    categories = [];
    allCategories = [];

    numberMask = createNumberMask({
        prefix: '',
        integerLimit: 2,
        thousandsSeparatorSymbol: '',
        allowDecimal: false
    });
    
    constructor(
        injector: Injector,
        private _categoryService: CategoriesServiceProxy
    ) {
        super(injector);
    }
  
    ngOnInit() {      
        this.loadCategories();
        this.checkCategories();
    }

    addCategory(categoryItem: CategoryListDto) {
      
        let category = new AnnuitySettingCategoryDto();
        category.id = this.countName--;
        category.categoryId = categoryItem.id;
        category.category = categoryItem;
        category.deadlines = [];

        let deadline = new AnnuitySettingCategoryDeadlineDto();
        deadline.id = this.countName--;
        category.deadlines.push(deadline);

        this.annuitySetting.categories.push(category);

        setTimeout(() => {
            var element = '#headingCategory' + (this.annuitySetting.categories.length - 1) + ' .card-title';
            $(element).trigger('click');
        }, 0);

        this.checkCategories();
    }

    deleteCategory(index: number): void {
        this.annuitySetting.categories.splice(index, 1);
        this.checkCategories();
    }

    addDeadline(index: number) {
        let deadline = new AnnuitySettingCategoryDeadlineDto();
        deadline.id = this.countName--;
        deadline.maxInstallments = 1;
        deadline.maxInstallmentsWithoutCardFee = 1;
        this.annuitySetting.categories[index].deadlines.push(deadline);
    }

    deleteDeadline(indexCategory: number, indexDeadline: number): void {
        this.annuitySetting.categories[indexCategory].deadlines.splice(indexDeadline, 1);
    }

    loadCategories(): void {
        this._categoryService.getForSelect("", undefined, undefined).subscribe(result => {
            this.allCategories = result.items;
            this.categories = _.filter(result.items, { isActive: true, isExempt: false });
            this.checkCategories();
        });
    }

    checkCategories() {

        if (this.annuitySetting && this.allCategories.length) {

            let categories = _.filter(this.allCategories, { isActive: true, isExempt: false });

            this.annuitySetting.categories.forEach(category => {
                
                let categoryFilter = _.filter(categories, { id: category.categoryId });

                if (categoryFilter.length)
                    categories = categories.filter(e => e.id !== categoryFilter[0].id);
            });

            this.categories = categories;
        }
    }

    chekIsCompleteDeadlineDate(el, i, j, el2) {

        var value = $(el).val().replaceAll('_', '');

        if (value.length == 0 || value.length == 10 || value.length == 12) {
            
            if (value == 'Invalid date' || !moment(value, 'DD/MM/YYYY').isValid() || moment(value, 'DD/MM/YYYY').isBefore(moment('1900/01/01'))) {
                setTimeout(() => {
                    $(el).val('');
                    this.annuitySetting.categories[i].deadlines[j].deadline = undefined;
                }, 0);
            }
        }
    }

    checkValue(i, j) {

        var value = this.annuitySetting.categories[i].deadlines[j].value;

        if ((value && +value < 1) || (value != undefined && +value == 0))
            this.annuitySetting.categories[i].deadlines[j].value = 1;
    }

    checkReadmissionValue(i, j) {

        var readmissionValue = this.annuitySetting.categories[i].deadlines[j].readmissionValue

        if ((readmissionValue && +readmissionValue < 1) || (readmissionValue != undefined && +readmissionValue == 0))
            this.annuitySetting.categories[i].deadlines[j].readmissionValue = 1;
    }

    checkInstallments(i, j): void {

        let deadline = this.annuitySetting.categories[i].deadlines[j];

        if (!+deadline.maxInstallments)
            deadline.maxInstallments = 1;

        if (!+deadline.maxInstallmentsWithoutCardFee)
            deadline.maxInstallmentsWithoutCardFee = 1;
        
        if (deadline.maxInstallmentsWithoutCardFee > deadline.maxInstallments)
            deadline.maxInstallmentsWithoutCardFee = deadline.maxInstallments;
    }
}
  