import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { curveBasis } from 'd3-shape';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class RegionalStatsTable extends DashboardChartBase {
  stats: Array<any>;
  colors = ['#00c5dc', '#f4516c', '#34bfa3', '#ffb822'];
  customColors = [
    { name: '1', value: '#00c5dc' },
    { name: '2', value: '#f4516c' },
    { name: '3', value: '#34bfa3' },
    { name: '4', value: '#ffb822' },
    { name: '5', value: '#00c5dc' },
    { name: '6', value: '#f4516c' },
    { name: '7', value: '#34bfa3' },
    { name: '8', value: '#ffb822' }
  ];

  curve: any = curveBasis;

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(startDate: any, endDate: any, eventId: number) {
    this.reload(startDate, endDate, eventId);
  }

  formatData(): any {
    for (let j = 0; j < this.stats.length; j++) {
      let stat = this.stats[j];

      let series = [];
      for (let i = 0; i < stat.change.length; i++) {
        series.push({
          name: i + 1,
          value: stat.change[i]
        });
      }

      stat.changeData = [
        {
          'name': j + 1,
          'series': series
        }
      ];

    }
  }

  reload(startDate: any, endDate: any, eventId: number) {
    this.showLoading();
    this._dashboardService
      .getPaymentStats(startDate, endDate, eventId)
      .subscribe(result => {
        this.stats = result.stats;
        this.formatData();
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-registration-regional-stats',
  templateUrl: './widget-registration-regional-stats.component.html',
  styleUrls: ['./widget-registration-regional-stats.component.css']
})
export class WidgetRegistrationRegionalStatsComponent extends WidgetComponentBase implements OnInit {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterEvent: number = undefined;

  regionalStatsTable: RegionalStatsTable;

  constructor(injector: Injector,
    private _dashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.regionalStatsTable = new RegionalStatsTable(this._dashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subEventFilter();
    this.runDelayed(() =>{
      this.reloadTab();
    });
  }

  reloadTab = () => {
    this.regionalStatsTable.init(this.selectedDateRange[0], this.selectedDateRange[1], this.filterEvent);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.reloadTab);
  }

  onEventFilterChange = (eventId: number) => {

    this.filterEvent = eventId;
    this.runDelayed(this.reloadTab);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subEventFilter() {
    abp.event.on('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  unSubEventFilter() {
    abp.event.off('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubEventFilter();
  }
}
