import { Component, OnInit, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { TokenService } from 'abp-ng2-module/dist/src/auth/token.service';

export class KtDatatableBase {
  IdName: string;
  Service: string;
  Columns: any;  
  Params : any;
  PrepareMap: any;
  Pagination: boolean = true;
  TotalItens: number;
}

export abstract class KtDatatableBaseParams {
  Sorting: any;
  MaxResultCount: any;
  SkipCount: any;
}

@Component({
  selector: 'ktDatatable',
  templateUrl: './kt-datatable.component.html'
})
export class KtDatatableComponent extends AppComponentBase implements OnInit {

  ktDatatable: any = {};

  @Input("ktDatatableOptions") public ktDatatableOptions : KtDatatableBase; 
  
  constructor(
    injector: Injector,    
    private _tokenService: TokenService
  ) {
    super(injector);
  }

  ngOnInit() {

    var self = this;

    setTimeout(() => {

      self.ktDatatable = $('#'+self.ktDatatableOptions.IdName).KTDatatable({
        data: {
          type: 'remote',
          source: {
            read: {
              method: 'GET',
              url: AppConsts.remoteServiceBaseUrl + "/api/services/app/" + self.ktDatatableOptions.Service,
              headers: {
                Authorization: 'Bearer ' + self._tokenService.getToken()
              },              
              params: self.prepareParams(),
              beforeSend: function() {
                $('.dropdown-menu.dropdown-menu-right.show').remove();
                self.primengTableHelper.showLoadingIndicator();
              },
              complete: function() {
                 self.primengTableHelper.hideLoadingIndicator();
              },
              map: function(raw) {

                let page = 1;
                let perpage = 10;

                if (!!self.ktDatatable.API && !!self.ktDatatable.API.params.pagination && !!self.ktDatatable.API.params.pagination.page) {
                  page = self.ktDatatable.API.params.pagination.page;
                  perpage = self.ktDatatable.API.params.pagination.perpage;
                }

                self.ktDatatable.lastResponse.meta = { 
                  page: page, 
                  perpage: perpage, 
                  pages: Math.ceil(raw.result.totalCount / perpage), 
                  total: raw.result.totalCount
                };

                self.ktDatatableOptions.TotalItens = raw.result.totalCount;

                let dataSet = raw.result.items;

                if (self.ktDatatableOptions.PrepareMap != undefined)
                  dataSet = self.ktDatatableOptions.PrepareMap(dataSet);

                if (raw.result.totalCount > 0 && dataSet.length == 0) {
                  self.ktDatatable.reload();
                }

                return dataSet;
              },
            },
          },          
          pageSize: 10, // display 10 records per page
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
        },
        toolbar: {
          items: {
            pagination: {
              pageSizeSelect: [5, 10, 25, 50, 100]
            }
          }
        },
        search: {
            input: $('#filterText'),
            onEnter: true
        },
        columns: self.ktDatatableOptions.Columns,
        layout: {
          scroll: false, // enable/disable datatable scroll both horizontal and vertical when needed.
          footer: false, // display/hide footer
        },        
        sortable: true,    
        pagination: self.ktDatatableOptions.Pagination,
        translate: {
          toolbar: {
            pagination: {
              items: {
                info: self.l('Displaying {{start}} - {{end}} of {{total}} records'),
                default: {
                  first: self.l('First'),
                  prev: self.l('Previous'),
                  next: self.l('Next'),
                  last: self.l('Last'),
                  more: self.l('More pages'),
                  input: self.l('Page number'),
                  select: self.l('Select page size')
                }
              }
            }
          },
          records: {
            noRecords: self.l('No records found')
          }
        }
      });

    }, 0);
  }

  prepareParams() {

    var self = this;

    self.ktDatatableOptions.Params.Sorting = function() {

      if (!!self.ktDatatable.API && !!self.ktDatatable.API.params.sort) {
        return self.ktDatatable.API.params.sort.field + ' ' + self.ktDatatable.API.params.sort.sort;
      }
      else {
        let kt_datatable = JSON.parse(localStorage.getItem(self.ktDatatableOptions.IdName + '-1-meta'));
        if (kt_datatable && kt_datatable.sort)
          return kt_datatable.sort.field + ' ' + kt_datatable.sort.sort;
        else                  
          return '';
      }
    };

    self.ktDatatableOptions.Params.MaxResultCount = function() {

      let perpage = 10;

      if (!!self.ktDatatable.API && self.ktDatatable.API.params.sort) {
        perpage = self.ktDatatable.API.params.pagination.perpage;                    
      }
      else {
        let kt_datatable = JSON.parse(localStorage.getItem(self.ktDatatableOptions.IdName + '-1-meta'));
        if (kt_datatable && kt_datatable.pagination)
          perpage =  kt_datatable.pagination.perpage;                    
      }

      if (!!!perpage)
        perpage = 10;
      
      return perpage;
    };

    self.ktDatatableOptions.Params.SkipCount = function() {

      let skipCount = 0;

      if (!!self.ktDatatable.API && !!self.ktDatatable.API.params.pagination.perpage) {
        skipCount = self.ktDatatable.API.params.pagination.perpage * (self.ktDatatable.API.params.pagination.page - 1);
      }
      else {
        let kt_datatable = JSON.parse(localStorage.getItem(self.ktDatatableOptions.IdName + '-1-meta'));
        if (kt_datatable && kt_datatable.pagination)
          skipCount =  kt_datatable.pagination.perpage * (kt_datatable.pagination.page - 1);
      }
      
      return skipCount;
    };

    return self.ktDatatableOptions.Params;
  }
}
