import { NgForm } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { OnInit, Input, ViewChild, Injector, Component, Output, EventEmitter } from "@angular/core";
import { AdDto, CreateOrEditActivityDto, CreateOrEditGuestRoleDto, CreateOrEditParticipationDto, EventScheduleDayDto, GuestAddressDto, GuestEmailDto, GuestPhoneDto, GuestsServiceProxy, MembersServiceProxy, TopicDto, TopicType, VideoDto } from "@shared/service-proxies/service-proxies";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { EventSchedulesHelperService, IEventScheduleSelected } from "@app/main/event-schedules/header/event-schedules-helper-service";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    moduleId: module.id,
    selector: 'activityTopics',
    templateUrl: './activity-topics.component.html',
    exportAs: 'activityTopicsComponent'
  })
  export class ActivityTopicsComponent extends AppComponentBase implements OnInit {
    
    @Input() day: EventScheduleDayDto;
    @Input() activity: CreateOrEditActivityDto;
    @Input() isTemplate: CreateOrEditActivityDto;
    @Output() goConflict: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('activityTopicsForm', { static: true }) activityTopicsForm: NgForm;
  
    countName = 0;
    topicType = TopicType;

    roles = [];
    speakers = [];
    
    numberMask = createNumberMask({
      prefix: '',
      decimalLimit: 2,
      thousandsSeparatorSymbol: '.',
      allowDecimal: false
    });

    eventScheduleSelected: IEventScheduleSelected = undefined;

    constructor(
      injector: Injector,
      private _membersService: MembersServiceProxy,
      private _guestsServiceProxy: GuestsServiceProxy,
      private _eventScheduleHelper: EventSchedulesHelperService
    ) {
      super(injector);

      this.eventScheduleSelected = this._eventScheduleHelper.loadEventScheduleSelected();
    }
  
    ngOnInit() {
      this.filterRoles({ query: '' });
      this.filterSpeakers({ query: '' });
      this.reloadOrderTopics();
    }

    addTopic(type: TopicType) {

      let description = '';

      if (type != TopicType.MainTopic && type != TopicType.SubTopic)
          description = this.getDescription(type);

      let topic = new TopicDto();        
      topic.id = this.countName--;
      topic.description = description;
      topic.type = type;
      topic.duration = '01:00';
      topic.participations = [];
      this.activity.topics.push(topic);
      this.reloadOrderTopics();

      if (this.activity.topics.length > 1)
      this.activity.topics[this.activity.topics.length - 1].startTime = this.activity.topics[this.activity.topics.length - 2].endTime;

      setTimeout(() => {
        var element = '#headingTopic' + (this.activity.topics.length - 1) + ' .card-title';
        $(element).trigger('click');
      }, 0);
  }

  deleteTopic(i: number): void {
    this.activity.topics.splice(i, 1);
    this.reloadOrderTopics();
    this.adjustHourstopic(i);
  };

  reloadOrderTopics(): void {

    let number = 1;
    for (let i = 0; i < this.activity.topics.length; i++) {

        this.activity.topics[i].order = i;

        switch (this.activity.topics[i].type) {
            case TopicType.MainTopic: {
                number = 1;
                this.activity.topics[i].number = 0;
                break;
            }
            case TopicType.SubTopic: {                    
                this.activity.topics[i].number = number++;
                break;
            }            
            default:
                break;
        }
    }
  }

  addParticipationTopic(i: number) {
    let participation = new CreateOrEditParticipationDto();
    participation.role = new CreateOrEditGuestRoleDto();
    participation.role.description = this.activity.topics[i].type == TopicType.MainTopic ? this.l('Coordinator') : this.l('Speaker');
    this.activity.topics[i].participations.push(participation);
    this.filterSpeakers({ query: '' });
  }

  deleteParticipationTopic(i: number, j: number): void {        
    this.activity.topics[i].participations.splice(j, 1);
  };

  checkDuration(i: number): void {

    if (!moment(this.activity.topics[i].duration, 'h:mm').isValid()) {
      this.clearDuration(i);        
    }
    else {
      let duration = moment(this.activity.topics[i].duration, "HH:mm").format("HH:mm");
      if (duration == '00:00') {
        this.clearDuration(i);
        this.message.warn(this.l('MinDurationInfo'), this.l('Warning'));
      }
    }
  }

  clearDuration(i) {
    setTimeout(() => {
      this.activity.topics[i].duration = undefined;
    }, 0);
  }

  dropTopic(event: CdkDragDrop<TopicDto[]>) {

    let topic = this.activity.topics[event.previousIndex];
    
    if (event.currentIndex > 0 || event.currentIndex == 0 && topic.type == TopicType.MainTopic) {
      moveItemInArray(this.activity.topics, event.previousIndex, event.currentIndex);
      this.reloadOrderTopics();
      this.adjustHourstopic(0);
    }
  }

  getDescription(type: TopicType): string {

    switch (type) {
        case TopicType.MainTopic:
            return this.l('MainTopic');
        case TopicType.SubTopic:
            return this.l('SubTopic');
        case TopicType.Lunch:
            return this.l('Lunch');
        case TopicType.CoffeeBreak:
            return this.l('CoffeeBreak');
        case TopicType.Interval:
            return this.l('Interval');
        default:
            break;
    }
  }

  geHour(hour: string): string {
    return moment(hour, 'HH:mm').format('HH:mm');
  }

  checkStartTime(i): void {

    if (!moment(this.activity.topics[i].startTime, 'h:mm').isValid()) {
      setTimeout(() => {
        this.activity.topics[i].startTime = undefined;
      }, 0);
    }
    else {
      
      if (this.activity.topics[i].endTime)
        this.activity.topics[i].endTime = moment(this.activity.topics[i].endTime, 'HH:mm').format("HH:mm");

      this.activity.topics[i].startTime = moment(this.activity.topics[i].startTime, 'HH:mm').format("HH:mm");

      let startTime = moment(this.activity.topics[i].startTime, 'HH:mm');
      let endTime = moment(this.activity.topics[i].endTime, 'HH:mm');
      let beginHourDay = this.day ? moment(this.day.beginHour, 'HH:mm') : undefined;
    
      if (this.activity.topics[i].startTime && (this.activity.topics[i].startTime == this.activity.topics[i].endTime)) {
        setTimeout(() => {
          this.message.warn(this.l('StartEndHourInfo'), this.l('Warning'));
          this.activity.topics[i].startTime = undefined;
        }, 0);
      }
      else if (beginHourDay && beginHourDay.isAfter(startTime)) {
        this.message.warn(this.l('MinStartHourActivity', beginHourDay.format("HH:mm")), this.l('Warning'));
        setTimeout(() => {
          this.activity.topics[i].startTime = undefined;
        }, 0);
      }
      else if (this.activity.topics[i].startTime && this.activity.topics[i].endTime) {        

        if (startTime.isAfter(endTime)) {
            this.message.warn(this.l('EndTimeBiggerStartTimeInfo'), this.l('Warning'));
            setTimeout(() => {
                this.activity.topics[i].startTime = undefined;
            }, 0);
        }        
        else {       
          let duration = moment.utc(moment(endTime,"HH:mm").diff(moment(startTime,"HH:mm"))).format("HH:mm");
          this.activity.topics[i].duration = duration;
        }
      }
    }
  }

  checkEndTime(i): void {

    if (!moment(this.activity.topics[i].endTime, 'h:mm').isValid()) {
        setTimeout(() => {
          this.activity.topics[i].endTime = undefined;
        }, 0);
    }
    else {

      if (this.activity.topics[i].startTime)
        this.activity.topics[i].startTime = moment(this.activity.topics[i].startTime, 'HH:mm').format("HH:mm");

      this.activity.topics[i].endTime = moment(this.activity.topics[i].endTime, 'HH:mm').format("HH:mm");

      let endTime = moment(this.activity.topics[i].endTime, 'HH:mm');
      let endHourDay = this.day ? moment(this.day.endHour, 'HH:mm') : undefined;

      if (this.activity.topics[i].endTime && (this.activity.topics[i].startTime == this.activity.topics[i].endTime)) {
        setTimeout(() => {
          this.message.warn(this.l('StartEndHourInfo'), this.l('Warning'));
          this.activity.topics[i].endTime = undefined;
        }, 0);
      }
      else if (endHourDay && endHourDay.isBefore(endTime)) {
        this.message.warn(this.l('MaxEndHourActivity', endHourDay.format("HH:mm")), this.l('Warning'));
        setTimeout(() => {
          this.activity.topics[i].endTime = undefined;
        }, 0);
      }
      else if (this.activity.topics[i].startTime && this.activity.topics[i].endTime) {

        let startTime = moment(this.activity.topics[i].startTime, 'HH:mm');      

        if (startTime.isAfter(endTime)) {
            this.message.warn(this.l('EndTimeBiggerStartTimeInfo'), this.l('Warning'));
            setTimeout(() => {
              this.activity.topics[i].endTime = undefined;
            }, 0);
        }        
        else {
          let duration = moment.utc(moment(endTime,"HH:mm").diff(moment(startTime,"HH:mm"))).format("HH:mm");
          this.activity.topics[i].duration = duration;
          this.adjustHourstopic(i);
        }
      }
      else {
        this.adjustHourstopic(i);
      }
    }
  }

  loadTopicHour() {
    
    setTimeout(() => {
      if (this.day && this.activity.topics.length) {
        this.activity.topics[0].startTime = this.day.beginHour;
        this.adjustHourstopic(0);
      }
    }, 0);
  }

  adjustHourstopic(index: number) {

    for (let i = index; i < this.activity.topics.length; i++) {
    
      if (i > 0)
        this.activity.topics[i].startTime = this.activity.topics[i-1].endTime;

      if (!this.activity.topics[i].duration || this.activity.topics[i].duration == '00:00')
        this.activity.topics[i].duration = '01:00';
      
      let startTime = moment(this.activity.topics[i].startTime, 'HH:mm');
      let duration = moment(this.activity.topics[i].duration, 'HH:mm');

      startTime.add(duration.minutes(), 'minutes');
      startTime.add(duration.hours(), 'hours');

      this.activity.topics[i].endTime = startTime.format('HH:mm');
    }
  }

  // Get Roles
  filterRoles(event): void {
    this._guestsServiceProxy.getGuestRolesForSelect(event.query, undefined, undefined, undefined).subscribe(result => {
      this.roles = result.items;
    });
  }

  // Check Selected User
  checkSelectedRole(topic: number, participation: number) {
    setTimeout(() => {

        let role:any = this.activity.topics[topic].participations[participation].role;
        if (role && !role.description) {

            let roleDto = new CreateOrEditGuestRoleDto();
            roleDto.description = role;
            this.activity.topics[topic].participations[participation].role = undefined;

            setTimeout(() => {
              this.activity.topics[topic].participations[participation].role = roleDto;
              this.verifySelectedUserTopic(topic, participation, true);
            }, 0);            
        }
        else
          this.verifySelectedUserTopic(topic, participation, true);
    }, 100);
  }

  // Get Speakers
  filterSpeakers(event): void {
    this._guestsServiceProxy.getSpeakersForSelect(event.query, this.eventScheduleSelected.id, undefined, undefined, undefined).subscribe(result => {
      this.speakers = result.items;
    });
  }

  // Check Selected User
  checkSelectedUser(topic: number, participation: number) {
    setTimeout(() => {
        let guest = this.activity.topics[topic].participations[participation].guest;
        if (guest) {
          if (!guest.id && !guest.userId)
            this.activity.topics[topic].participations[participation].guest = undefined;
          else
            this.verifySelectedUserTopic(topic, participation, false);
        }        
        this.filterSpeakers({ query: '' });
    }, 0);
  }

  // Verify Selected User Topic
  verifySelectedUserTopic(topic: number, participation: number, isRole: boolean) {

    let _participation = this.activity.topics[topic].participations[participation];

    if (_participation.guest) {
      let guest = cloneDeep(_participation.guest);
      
      if (_participation.guest.name)
        _participation.guest.name = _participation.guest.name + " ";      

      setTimeout(() => {

        _participation.guest = guest;

        if (_participation.role) {
      
          let participations = this.activity.topics[topic].participations.filter(x => x.role.description.toLocaleLowerCase() == _participation.role.description.toLocaleLowerCase() && x.guest.name == _participation.guest.name)
    
          if (participations.length > 1) {
    
            if (isRole)
              this.activity.topics[topic].participations[participation].role = undefined;
            else
              this.activity.topics[topic].participations[participation].guest = undefined;
    
            this.message.warn(this.l('ConflictPartipantTopicError'));
          }
        }        
      }, 0);
    }
  }

  // Load User
  loadUser(topic: number, participation: number) {
    
    this.activity.topics[topic].participations[participation].guest.eventScheduleId = this.eventScheduleSelected.id;
    let guest = this.activity.topics[topic].participations[participation].guest;
    
    if (guest.userId) {
        this._membersService.getMemberByUserIdForView(guest.userId, undefined)
            .subscribe(result => {
                
              this.activity.topics[topic].participations[participation].guest.name = result.member.user.name
              this.activity.topics[topic].participations[participation].guest.nameForCredential = result.member.badgeName;
              this.activity.topics[topic].participations[participation].guest.email = result.member.user.emailAddress;
              this.activity.topics[topic].participations[participation].guest.cpf = result.member.cpf;
              this.activity.topics[topic].participations[participation].guest.crm = result.member.crm;
              this.activity.topics[topic].participations[participation].guest.rg = result.member.rg;
              this.activity.topics[topic].participations[participation].guest.rgEmitter = result.member.rgEmitter;
              this.activity.topics[topic].participations[participation].guest.rgEmitterUf = result.member.rgEmitterUf;
              this.activity.topics[topic].participations[participation].guest.addresses = result.member.user.addresses as GuestAddressDto[];
              this.activity.topics[topic].participations[participation].guest.phones = result.member.user.phones as GuestPhoneDto[];
              this.activity.topics[topic].participations[participation].guest.emails = result.member.user.emails as GuestEmailDto[];
              this.activity.topics[topic].participations[participation].guest.userId = guest.userId;

              this.activity.topics[topic].participations[participation].guest.addresses.forEach(address => {
                address.id = undefined;                      
              });

              this.activity.topics[topic].participations[participation].guest.phones.forEach(phone => {
                phone.id = undefined;                      
              });

              this.activity.topics[topic].participations[participation].guest.emails.forEach(email => {
                email.id = undefined;                      
              });                
            });
    }
  }

  addVideo(i) {    
    let video = new VideoDto();
    video.ads = [];    
    this.activity.topics[i].video = video;
  }

  deleteVideo(i) {
    this.activity.topics[i].video = undefined;
  }

  addAd(i) {    
    let ad = new AdDto();
    this.activity.topics[i].video.ads.push(ad);

    setTimeout(() => {
      var element = '#headingAd' + i + '-' + (this.activity.topics[i].video.ads.length - 1) + ' .card-title';
      $(element).trigger('click');
    }, 0);
  }

  deleteAd(i, j) {
    this.activity.topics[i].video.ads.splice(j, 1);
  }

  goToConflict() {
    this.goConflict.emit();
  }
}
  