import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class DashboardFinancialOverdueOrders extends DashboardChartBase {

  overdueOrders = 0; overdueOrdersCounter = 0;
  overdueOrdersChange = 0; overdueOrdersChangeCounter = 0;

  init(overdueOrders, overdueOrdersChange) {
    this.overdueOrders = overdueOrders;
    this.overdueOrdersChange = overdueOrdersChange
    this.hideLoading();
  }
}

@Component({
  selector: 'app-widget-financial-overdue-orders',
  templateUrl: './widget-financial-overdue-orders.component.html',
  styleUrls: ['./widget-financial-overdue-orders.component.css']
})
export class WidgetFinancialOverdueOrdersComponent extends WidgetComponentBase implements OnInit {

  dashboardTopStats: DashboardFinancialOverdueOrders;
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  
  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.dashboardTopStats = new DashboardFinancialOverdueOrders();
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.runDelayed(this.loadTopStatsData);
  }

  loadTopStatsData = () => {
    this._tenantDashboardServiceProxy.getFinancialOverdueOrders(this.selectedDateRange[0], this.selectedDateRange[1], undefined).subscribe((data) => {
      this.dashboardTopStats.init(data.total, data.totalChange);
    });
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.loadTopStatsData);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }
}
