import { NgForm } from '@angular/forms';
import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AddressComponent } from '@app/shared/common/address/address.component';
import { PhoneComponent } from '@app/shared/common/phone/phone.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressType, CheckUserInput, PhoneType, UpdateUserMainDataDto, UserAddressDto, UserPhoneDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { cloneDeep } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'editUserModal',
    templateUrl: './edit-User-modal.component.html'
})
export class EditUserModalComponent extends AppComponentBase {

    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('userModal', {static: true}) modal: ModalDirective;
    @ViewChild('userModalForm', { static: false }) userModalForm: NgForm;    
    @ViewChild('address', { static: false }) address: AddressComponent;
    @ViewChild('phone', { static: false }) phone: PhoneComponent;

    phoneDto = UserPhoneDto;
    addressDto = UserAddressDto;

    public active = false;    
    public saving = false;

    user: UpdateUserMainDataDto;
    
    constructor(
        injector: Injector,
        private _userService: UserServiceProxy
    ) {
        super(injector);
    }

    show(userId: number): void {

        this._userService.getUserMainData(userId)
            .subscribe(result => {

                this.user = result;
                
                if (!this.user.mainPhone) {
                    this.user.mainPhone = new UserPhoneDto();
                    this.user.mainPhone.ddi = '55';
                    this.user.mainPhone.type = PhoneType.Mobile;
                }

                if (!this.user.mainAddress) {
                    this.user.mainAddress = new UserAddressDto();
                    this.user.mainAddress.countryName = 'Brasil';
                    this.user.mainAddress.type = AddressType.Home;
                    this.user.mainAddress.isMailing = true;
                }

                this.active = true;
                this.modal.show();                
            });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
        this.modalClose.emit()
        this.closeModal();
    }

    save(): void {

        this.saving = true;
        let model = cloneDeep(this.user);

        this._userService.updateUserMainData(model)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(result => {
                this.notify.success(this.l('SavedSuccessfully'));
                this.close();
            });
    }

    formPhoneIsInvalid(): boolean {
        return this.phone && this.phone.phoneForm.invalid;;
    }

    formAddressIsInvalid(): boolean {
        return this.address && this.address.addressForm.invalid;
    }

    formIsValid(): boolean {
        return this.userModalForm && this.userModalForm.form.valid && !this.formPhoneIsInvalid() && !this.formAddressIsInvalid()
    }

    checkEmailAddress() {

        if (this.user.emailAddress) {

            let input = new CheckUserInput();
            input.emailAddress = this.user.emailAddress;        

            this._userService.checkUserForCreateOrUpdate(input)
                .subscribe(result => {

                    if (result.items.length > 0 && result.items[0].id != this.user.id) {
                        this.message.warn(this.l('UserErrorCheckUserEmail', this.user.emailAddress));
                        this.user.emailAddress = '';
                    }
            });
        }
    }
}
