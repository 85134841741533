import { Component, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MembersServiceProxy, CreateOrEditDocumentationDto, EntityDtoOfInt64, DisapproveDocDto, DocumentationStatus, CreateOrEditDocumentDto } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'approveDocumentsModal',
    templateUrl: './approve-documents-modal.component.html'
})
export class ApproveDocumentsModalComponent extends AppComponentBase {

    @ViewChild('approveDocumentsModal', { static: true }) modal: ModalDirective;

    active = false;
    saving = false;
    
    memberId: number;
    documentation: CreateOrEditDocumentationDto;
    documentationStatus = DocumentationStatus;

    selectDocumentationStatus: DocumentationStatus = undefined;
    inputMotiveDisapproveDoc = '';
    
    constructor(
        injector: Injector,
        private _membersServiceProxy: MembersServiceProxy,
        private _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    show(memberId: number): void {
        this.memberId = memberId;
        this.loadDocumentations();
    }

    close(): void {        
        this.active = false;
        this.inputMotiveDisapproveDoc = '';
        this.selectDocumentationStatus = undefined;
        this.modal.hide();
        this.closeModal();
    }

    save(): void {

        if (this.selectDocumentationStatus == DocumentationStatus.Approved)
            this.approve();
        else
            this.disapprove();
    }

    loadDocumentations() {
    
        this.documentation = new CreateOrEditDocumentationDto();
        this.documentation.documents = [];    
    
        this._membersServiceProxy.getDocumentationForEdit(undefined, this.memberId)
            .pipe(finalize(() => { 
                this.active = true;
                this.modal.show();
            }))
            .subscribe(result => {          
                if (!!result.id)
                this.documentation = result;
            }
        );
      }

    approve() {

        this.message.confirm(
            this.l('ApproveDocumentsWarningMessage'),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
    
                    let input = new EntityDtoOfInt64();
                    input.id = this.documentation.id
                    
                    this.saving = true;
                    this._membersServiceProxy.approveDocumentation(input)
                        .pipe(finalize(() => { this.saving = false; }))
                        .subscribe(result => {
                
                            if (result.errorOnSendEmail)
                              this.message.info(this.l('SavedInformationsErrorOnSendEmailMessage'), this.l('Info'))
                            else
                              this.notify.success(this.l('SavedSuccessfully'));

                            abp.event.trigger('app.save.approve.documentation');
                            this.close();
                        }
                    );
                }
            }
        );
    }
    
    disapprove() {
    
        this.message.confirm(
            this.l('DisapproveDocumentsWarningMessage'),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
    
                    let input = new DisapproveDocDto();
                    input.id = this.documentation.id;
                    input.note = this.inputMotiveDisapproveDoc;
        
                    this.saving = true;
                    this._membersServiceProxy.disapproveDocumentation(input)
                        .pipe(finalize(() => { this.saving = false; }))
                        .subscribe(result => {
                
                            if (result.errorOnSendEmail)
                              this.message.info(this.l('SavedInformationsErrorOnSendEmailMessage'), this.l('Info'))
                            else
                              this.notify.success(this.l('SavedSuccessfully'));
                            
                            abp.event.trigger('app.save.approve.documentation');                            
                            this.close();
                        }
                    );
                }
            }
        );
    }

    downloadFile(document: CreateOrEditDocumentDto): void {

        this.saving = true;
                
        this._membersServiceProxy
            .getDocumentFile(document.documentId, document.fileExtension, document.id)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(result => {
              this._fileDownloadService.downloadTempFile(result);            
        });
    }
}
