import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { AnnuitySituation, CreateOrEditDocumentationDto, DocumentationStatus, GetMyDocumentationsOutput, MembersServiceProxy, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

class MyDocumentation extends DashboardChartBase {

  documentation: GetMyDocumentationsOutput;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getMyDocumentationsList(this.userLoggedId)
      .subscribe(result => {
        this.documentation = result;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-my-documentations',
  templateUrl: './widget-my-documentations.component.html',
  styleUrls: ['./widget-my-documentations.component.css']
})
export class WidgetMyDocumentationsComponent extends WidgetComponentBase implements OnInit {

  showMessage = false
  myDocumentation: MyDocumentation;  
  documentationStatus = DocumentationStatus;
  documentation: CreateOrEditDocumentationDto;  
  memberId: number;

  constructor(injector: Injector,
    private _router: Router,
    private _membersServiceProxy: MembersServiceProxy,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.myDocumentation = new MyDocumentation(this._dashboardService);
  }

  ngOnInit() {
    this.reloadDocumentation();
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.loadMember();
    this.myDocumentation.init();
  }

  uploadFiles(): void {
    abp.event.trigger('app.show.documentation', this.documentation);
  }

  loadMember() {

    let userLoggedId = abp.session.userId;
    this.documentation = new CreateOrEditDocumentationDto();
    this.documentation.documents = [];    

    this._membersServiceProxy.getMemberByUserIdForView(userLoggedId, undefined)
        .subscribe(result => {
          if (result.member) {
            this.memberId = result.member.id;
            this.loadDocumentations();
          }
          else {
            this.showMessage = true;
          }
        });
  }

  loadDocumentations() {
    this._membersServiceProxy.getDocumentationForEdit(undefined, this.memberId)
      .pipe(finalize(() => { this.showMessage = true; }))      
      .subscribe(result => {          
        if (!!result.id) {
          this.documentation = result;
        }
        this.documentation.memberId = this.memberId;
      }
    );
  }

  reloadDocumentation() {
    abp.event.on('app.save.documentation', this.reloadTab);
  }
}