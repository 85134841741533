import { Component, Input, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SocialNetworkName } from '@shared/service-proxies/service-proxies';

@Component({
  moduleId: module.id,
  selector: 'socialNetworks',
  templateUrl: './social-networks.component.html',
  exportAs: 'socialNetworksComponent'
})

export class SocialNetworksComponent extends AppComponentBase implements OnInit {

  @Input("socialNetworks") _socialNetworks;
  @Input("socialNetworkDto") SocialNetworkDto;  
  @ViewChild('socialNetworksForm', { static: false }) public socialNetworksForm: NgForm;

  socialNetworkName = SocialNetworkName;

  public socialNetworks = [];
  countName = 0;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this._socialNetworks.forEach(socialNetwork => {
      socialNetwork.id = this.countName--;
    });
    this.socialNetworks = this._socialNetworks;
  }

  deleteSocialNetwork(index: number): void {
    this.socialNetworks.splice(index, 1);
  };

  addSocialNetwork(): void {
    let socialNetwork = new this.SocialNetworkDto();    
    socialNetwork.id = this.countName--;
    this.socialNetworks.push(socialNetwork);
  };  
}
