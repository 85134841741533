import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { SalesSummaryDatePeriod, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';
import * as _ from 'lodash';

class SalesSummaryChart extends DashboardChartBase {

  showMessage: boolean;

  chartData: any[] = [];
  scheme: any = {
    name: 'custom',
    selectable: true,
    group: 'Ordinal',
    domain: [
      '#1dc9b7', '#fd397a'
    ]
  };

  totalSales = 0; totalSalesCounter = 0;
  revenue = 0; revenuesCounter = 0;
  paids = 0; paidsCounter = 0;  

  selectedDatePeriod: SalesSummaryDatePeriod;
  
  data = [];
  self = this;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(salesSummaryData, totalSales, revenue, paids) {
    this.totalSales = totalSales;
    this.totalSalesCounter = totalSales;

    this.revenue = revenue;
    this.paids = paids;

    this.setChartData(salesSummaryData);
    this.hideLoading();
  }

  setChartData(items): void {
    
    let formattedData = [];
    
    formattedData.push({
      'name': 'Total Líquido',
      'value': items[0].profit,
      'color': '#1dc9b7'
    });

    formattedData.push({
      'name': 'Descontos',
      'value': items[0].discount,
      'color': '#fd397a'
    });

    this.chartData = formattedData;
  }

  reload(datePeriod, startDate, endDate) {
    
    this.selectedDatePeriod = datePeriod;    

    this.showLoading();
    this._dashboardService
      .getFinancialSalesSummary(datePeriod, startDate, endDate, undefined)
      .subscribe(result => {
        this.totalSales = result.totalSales;
        this.paids = result.paids;
        this.revenue = result.totalDiscounts;

        this.setChartData(result.salesSummary);
        this.showMessage = true;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-financial-sales-summary',
  templateUrl: './widget-financial-sales-summary.component.html',
  styleUrls: ['./widget-financial-sales-summary.component.css']
})
export class WidgetFinancialSalesSummaryComponent extends WidgetComponentBase implements OnInit, OnDestroy {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  salesSummaryChart: SalesSummaryChart;
  appSalesSummaryDateInterval = SalesSummaryDatePeriod;

  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy) {
    super(injector);
    this.salesSummaryChart = new SalesSummaryChart(this._tenantDashboardServiceProxy);
  }

  ngOnInit(): void {
    this.subDateRangeFilter();
    this.runDelayed(() => {
      this.reloadChart();
    });
  }

  reloadChart = () => {
    this.salesSummaryChart.reload(SalesSummaryDatePeriod.Daily, this.selectedDateRange[0], this.selectedDateRange[1]);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];

    this.runDelayed(this.reloadChart);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }

  getPercent(value: number, valueTotal: number): string {
    let percent = value * 100 / valueTotal;
    return percent.toFixed(0);
  }
}
