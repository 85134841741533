import { Component, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet><ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea"></ngx-spinner>`
})
export class RootComponent {

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
      let loader = this.renderer.selectRootElement('#loader');
      this.renderer.setStyle(loader, 'display', 'none');
    }

}
