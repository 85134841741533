import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UpdateUserMainDataDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    moduleId: module.id,
    selector: 'userMainData',
    templateUrl: './user-main-data.component.html',
    exportAs: 'userMainDataComponent'

})
export class UserMainDataComponent extends AppComponentBase {

    userId: number;
    user: UpdateUserMainDataDto;
    
    constructor(
        injector: Injector,
        private _userService: UserServiceProxy
    ) {
        super(injector);
    }

    loadUser(userId): void {
        
        this.userId = userId;

        if (this.userId) {
            this._userService.getUserMainData(this.userId).subscribe(result => {
                this.user = result;
            });
        }
        else { 
            this.clearUser();
        }
    }

    reloadUser(): void {
        this.loadUser(this.userId);;
    }

    clearUser(): void {
        this.userId = undefined;
        this.user = undefined;
    }
}
