import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class DashboardAnnuityNewReadmissions extends DashboardChartBase {

  newReadmissions = 0; newReadmissionsCounter = 0;
  newReadmissionsChange = 0; newReadmissionsChangeCounter = 0;

  init(newReadmissions, newReadmissionsChange) {
    this.newReadmissions = newReadmissions;
    this.newReadmissionsChange = newReadmissionsChange
    this.hideLoading();
  }
}

@Component({
  selector: 'app-widget-annuity-new-readmissions',
  templateUrl: './widget-annuity-new-readmissions.component.html',
  styleUrls: ['./widget-annuity-new-readmissions.component.css']
})
export class WidgetAnnuityNewReadmissionsComponent extends WidgetComponentBase implements OnInit {

  dashboardTopStats: DashboardAnnuityNewReadmissions;
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterAnnuitySetting: number = undefined;

  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.dashboardTopStats = new DashboardAnnuityNewReadmissions();
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subAnnuitySettingFilter();
    this.runDelayed(this.loadTopStatsData);
  }

  loadTopStatsData = () => {
    this._tenantDashboardServiceProxy.getAnnuityNewReadmissions(this.selectedDateRange[0], this.selectedDateRange[1], this.filterAnnuitySetting).subscribe((data) => {
      this.dashboardTopStats.init(data.total, data.totalChange);
    });
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.loadTopStatsData);
  }

  onAnnuitySettingChange = (annuitySettingId: number) => {
    this.filterAnnuitySetting = annuitySettingId;
    this.runDelayed(this.loadTopStatsData);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subAnnuitySettingFilter() {
    abp.event.on('app.dashboardFilters.annuitySettingFilter.onEventChange', this.onAnnuitySettingChange);
  }

  unSubAnnuitySettingFilter() {
    abp.event.off('app.dashboardFilters.annuitySettingFilter.onEventChange', this.onAnnuitySettingChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubAnnuitySettingFilter();
  }
}
