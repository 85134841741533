export class DashboardCustomizationConst {
    static widgets = {
        tenant: {

            generalDocumentsApprove: 'Widgets_Tenant_GeneralDocumentsApprove',
            generalDocumentsApproveCounter: 'Widgets_Tenant_GeneralDocumentsApproveCounter',
            generalTotalProfit: 'Widgets_Tenant_GeneralTotalProfit',
            generalTotalReceivables: 'Widgets_Tenant_GeneralTotalReceivables',
            generalNewMembersComponent: 'Widgets_Tenant_GeneralNewMembers',

            myDocumentations: 'Widgets_Tenant_MyDocumentations',
            myAnnuities: 'Widgets_Tenant_MyAnnuities',
            myNextEvents: 'Widgets_Tenant_MyNextEvents',
            myRegistrations: 'Widgets_Tenant_MyRegistrations',
            myOrders: 'Widgets_Tenant_MyOrders',
            
            registrationTotalProfit: 'Widgets_Tenant_RegistrationTotalProfit',
            registrationTotalReceivables: 'Widgets_Tenant_RegistrationTotalReceivables',
            registrationNewRegistrations: 'Widgets_Tenant_RegistrationNewRegistrations',
            registrationApproval: 'Widgets_Tenant_RegistrationApproval',
            registrationProfitShare: 'Widgets_Tenant_RegistrationProfitShare',
            registrationMemberActivity: 'Widgets_Tenant_RegistrationMemberActivity',
            registrationRegionalStats: 'Widgets_Tenant_RegistrationRegionalStats',
            registrationSalesSummary: 'Widgets_Tenant_RegistrationSalesSummary',            
            registrationGeneralStats: 'Widgets_Tenant_RegistrationGeneralStats',
            registrationDailySales: 'Widgets_Tenant_RegistrationDailySales',

            annuityTotalProfit: 'Widgets_Tenant_AnnuityTotalProfit',
            annuityTotalReceivables: 'Widgets_Tenant_AnnuityTotalReceivables',
            annuityNewAnnuities: 'Widgets_Tenant_AnnuityNewAnnuities',
            annuityNewReadmissions: 'Widgets_Tenant_AnnuityNewReadmissions',
            annuityRegionalStats: 'Widgets_Tenant_AnnuityRegionalStats',
            annuitySalesSummary: 'Widgets_Tenant_AnnuitySalesSummary',
            annuityProfitShare: 'Widgets_Tenant_AnnuityProfitShare',
            annuityGeneralStats: 'Widgets_Tenant_AnnuityGeneralStats',
            
            financialTotalProfit: 'Widgets_Tenant_FinancialTotalProfit',
            financialTotalReceivables: 'Widgets_Tenant_FinancialTotalReceivables',
            financialNewOrders: 'Widgets_Tenant_FinancialNewOrders',
            financialOverdueOrders: 'Widgets_Tenant_FinancialOverdueOrders',
            financialRegionalStats: 'Widgets_Tenant_FinancialRegionalStats',
            financialSalesSummary: 'Widgets_Tenant_FinancialSalesSummary',
            financialProfitShare: 'Widgets_Tenant_FinancialProfitShare',
            financialGeneralStats: 'Widgets_Tenant_FinancialGeneralStats',
        },
        host: {
            topStats: 'Widgets_Host_TopStats',
            incomeStatistics: 'Widgets_Host_IncomeStatistics',
            editionStatistics: 'Widgets_Host_EditionStatistics',
            subscriptionExpiringTenants: 'Widgets_Host_SubscriptionExpiringTenants',
            recentTenants: 'Widgets_Host_RecentTenants'
        }
    };
    static filters = {
        filterDateRangePicker: "Filters_DateRangePicker",
        filterEvent: "Filters_Event"
    };
    static dashboardNames = {
        defaultTenantDashboard: 'TenantDashboard',
        defaultHostDashboard: 'HostDashboard',
    };
    static Applications = {
        Angular: 'Angular'
    };
}
