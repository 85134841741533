import { PaymentMethodDto, PaymentMethodPermissionDto, PaymentMethodsServiceProxy, PaymentType } from '@shared/service-proxies/service-proxies';
import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'selectGatewayModal',
    templateUrl: './select-gateway-modal.component.html'
})

export class SelectGatewayModalComponent extends AppComponentBase {

    @ViewChild('modal', {static: true}) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    
    public active = false;    
    public saving = false;

    paymentType: PaymentType;
    paymentMethod: PaymentMethodDto;
    paymentMethods: PaymentMethodDto[] = [];
        
    constructor(
        injector: Injector,
        private _paymentMethodsService: PaymentMethodsServiceProxy
    ) {
        super(injector);
    }

    show(paymentType: PaymentType): void {

        this.paymentType = paymentType;
        
        this._paymentMethodsService.getPaymentMethodsToPayment(paymentType)
            .subscribe((result: PaymentMethodDto[]) => {
                this.paymentMethods = result;
                this.active = true;
                this.modal.show();
            });
    }

    close(): void {
        this.active = false;
        this.paymentMethod = undefined;
        this.modal.hide();        
        this.closeModal();
    }

    save(): void {

        let newPaymentMethod = new PaymentMethodPermissionDto();
        newPaymentMethod.isActive = true;
        newPaymentMethod.discountPercentage = 0;
        newPaymentMethod.description = this.paymentMethod.description;
        newPaymentMethod.paymentType = this.paymentMethod.paymentType;
        newPaymentMethod.pagSeguroConfigId = this.paymentMethod.pagSeguroConfigId;

        this.modalSave.emit(newPaymentMethod);
        this.close();
    }
}
