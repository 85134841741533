import { Component, Input, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PhoneType } from '@shared/service-proxies/service-proxies';

@Component({
  moduleId: module.id,
  selector: 'phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.less'],
  exportAs: 'phoneComponent'
})

export class PhoneComponent extends AppComponentBase implements OnInit {

  @Input("phone") _phone;
  @Input("phoneDto") PhoneDto;  
  @Input("isWizard") isWizard;
  @ViewChild('phoneForm', { static: false }) public phoneForm: NgForm;

  phoneType = PhoneType;

  public phone = undefined;
  countName = 0;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.phone = this._phone;
  }
}
