import { EventSchedulesServiceProxy } from '@shared/service-proxies/service-proxies';
import { Component, Injector, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EventSchedulesHelperService, IEventScheduleSelected } from './event-schedules-helper-service';
import * as _ from 'lodash';

@Component({
    templateUrl: './header-event-schedules.component.html',
    styleUrls: ['./header-event-schedules.component.less'],
    selector: '[headerEventSchedules]',
    encapsulation: ViewEncapsulation.None
})
export class HeaderEventSchedulesComponent extends AppComponentBase implements OnInit {

    eventScheduleSelected: IEventScheduleSelected = undefined;
    eventSchedules = [];
    
    constructor(
        injector: Injector,
        private _eventSchedulesServiceProxy: EventSchedulesServiceProxy,
        private _eventScheduleHelper: EventSchedulesHelperService,
        public _zone: NgZone
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.eventScheduleSelected = this._eventScheduleHelper.loadEventScheduleSelected();
        this.loadProgramming();
        this.registerToEvents();
    }

    registerToEvents() {
        abp.event.on('app.programmingHeader.refresh', () => {
            this.loadProgramming();
        });
    }
    
    loadProgramming(): void {

        this.eventScheduleSelected = this._eventScheduleHelper.loadEventScheduleSelected();

        this._eventSchedulesServiceProxy.getAll(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            4,
        )          
        .subscribe(result => {

            this.eventSchedules = [];

            result.items.forEach(eventSchedule => {

                if (this.eventSchedules.length < 3 && (!this.eventScheduleSelected || this.eventScheduleSelected.id != eventSchedule.eventSchedule.id))
                    this.eventSchedules.push(eventSchedule);                
            });

            if (result.totalCount == 0) {
                this._eventScheduleHelper.remove();
                this.eventScheduleSelected = undefined;
            }
            else if (!this.eventScheduleSelected && result.totalCount > 0) {
                this.saveEventSchedule(result.items[0]);
                abp.event.trigger('app.programmingHeader.refresh');
            }
        });
    }

    saveEventSchedule(eventSchedule, reload?:boolean): void {

        let eventScheduleSelected: IEventScheduleSelected = { 
            id: eventSchedule.eventSchedule.id,
            name: eventSchedule.eventSchedule.description,
            calendarId: eventSchedule.eventSchedule.calendarId,
            userId: this.appSession.user.id,
            tenantId: this.appSession.tenantId
        };

        this.save(eventScheduleSelected, reload);
        abp.event.trigger('app.programmingHeader.refresh');
        this.notify.success(this.l('SavedSuccessfully'));
    }

    saveEventScheduleConfirm(eventSchedule, reload?:boolean): void {

        this.message.confirm(
            this.l('ConfirmChangeEventSchedule', eventSchedule.eventSchedule.description),
            this.l('AreYouSureManagementEventSchedule'),
            (isConfirmed) => {
                if (isConfirmed) {
                    let eventScheduleSelected: IEventScheduleSelected = { 
                        id: eventSchedule.eventSchedule.id,
                        name: eventSchedule.eventSchedule.description,
                        calendarId: eventSchedule.eventSchedule.calendarId,
                        userId: this.appSession.user.id,
                        tenantId: this.appSession.tenantId
                    };
            
                    this.save(eventScheduleSelected, reload);
                    abp.event.trigger('app.programmingHeader.refresh');
                    this.notify.success(this.l('SavedSuccessfully'));
                }
            }
        );
    }
    
    save(eventSchedule, reload?:boolean): void {

        this._eventScheduleHelper.save(eventSchedule);
        this.eventScheduleSelected = eventSchedule;

        if (reload) {
            window.location.reload();
        }
    }
}
