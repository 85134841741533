import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class GeneralStatsPieChart extends DashboardChartBase {

  showMessage: boolean;

  public data = [];
  colorScheme = {
    domain: ['#1DC9B7', '#FFB823', '#A10A28']
  };

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(transactionPercent, newVisitPercent, bouncePercent) {
    this.data = [
      {
        'name': "Pagas",
        'value': transactionPercent,
        
      }, {
        'name': 'Não pagas',
        'value': newVisitPercent
      },
      {
        'name': 'Canceladas',
        'value': bouncePercent
      }
    ];  

    this.hideLoading();
  }

  reload(startDate: any, endDate: any) {
    this.showLoading();
    this._dashboardService
      .getFinancialGeneralStats(startDate, endDate, undefined)
      .subscribe(result => {
        this.init(result.transactionPercent, result.newVisitPercent, result.bouncePercent);
        this.showMessage = true;
      });
  }
}
@Component({
  selector: 'app-widget-financial-general-stats',
  templateUrl: './widget-financial-general-stats.component.html',
  styleUrls: ['./widget-financial-general-stats.component.css']
})
export class WidgetFinancialGeneralStatsComponent extends WidgetComponentBase implements OnInit {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  
  generalStatsPieChart: GeneralStatsPieChart;
  constructor(injector: Injector,
    private _dashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.generalStatsPieChart = new GeneralStatsPieChart(this._dashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.runDelayed(() => {
      this.reloadChart();
    })
  }

  reloadChart = () => {
    this.generalStatsPieChart.reload(this.selectedDateRange[0], this.selectedDateRange[1]);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.reloadChart);
  }


  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }
}
