import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy, UserNotification, UserNotificationState } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import { KtDatatableComponent, KtDatatableBaseParams, KtDatatableBase } from '@app/shared/common/kt-datatable/kt-datatable/kt-datatable.component';
declare var $:any;

export class KtDatatableNotificationParams extends KtDatatableBaseParams {
    State: any;
    StartDate: any;
    EndDate: any;
}

@Component({
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class NotificationsComponent extends AppComponentBase {

    @ViewChild('ktDatatable', { static: false }) ktDatatable: KtDatatableComponent;

    readStateFilter = 'ALL';
    dateRange: Date[] = [moment().startOf('day').toDate(), moment().endOf('day').toDate()];
    loading = false;

    ktDatatableOptions: KtDatatableBase;
    filterText = '';

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadKtDatatableOptions();
    }

    ngAfterViewInit(): void {

        var self = this;

        setTimeout(() => {

            if (this.ktDatatable && this.ktDatatable.ktDatatable) {

                this.ktDatatable.ktDatatable.on('click', '.notification-read', function () {
                    let record = new UserNotification();
                    record.id = $(this).data('id');
                    self.setAsRead(record);
                });

                this.ktDatatable.ktDatatable.on('click', '.notification-delete', function () {
                    let record = new UserNotification();
                    record.id = $(this).data('id');
                    self.deleteNotification(record);
                });
            }
            else {
                this.ngAfterViewInit();
            }
        }, 200);
    }

    loadKtDatatableOptions() {

        var self = this;

        let options = new KtDatatableBase();
        options.IdName = 'kt_datatable_notification';
        options.Service = 'Notification/GetUserNotifications?';
        options.Params = new KtDatatableNotificationParams();
        options.Params.State = function () {
            return self.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread
        };
        options.Params.StartDate = function () {
            return moment(self.dateRange[0]).format()
        };
        options.Params.EndDate = function () {
            return moment(self.dateRange[1]).endOf('day').format()
        }
        options.Columns = [
            // {
            //     field: 'id',
            //     title: '#',
            //     sortable: false,
            //     width: 20,
            //     autoHide: false,
            //     selector: {
            //         class: 'kt-checkbox--solid'
            //     },
            //     textAlign: 'center',
            // },
            {
                field: "notification.data.message",
                title: self.l("Notification"),
                sortable: false,
                autoHide: false,
                template : function(record) {

                    if (record.formattedNotification.url) {
                        return `<a href="${record.formattedNotification.url}"
                                   class="${self.getRowClass(record)}">
                                        ${self.truncateString(record.formattedNotification.text, 120)}
                                </a>`;
                    }
                    else {
                        return `<span title="${record.formattedNotification.text}"
                                      class="${self.getRowClass(record)}">${self.truncateString(record.formattedNotification.text, 120)}
                                </span>/`;  
                    }                        
                }
            },
            {
                field: "creationTime",
                title: self.l("CreationTime"),
                sortable: false,
                template: function (record) {
                    return `<span title="${self.fromNow(record.notification.creationTime)}"
                        class="${self.getRowClass(record)}">
                        ${self.fromNow(record.notification.creationTime)}
                    </span>`;
                }
            },
            {
                field: '',
                title: self.l("Actions"),
                sortable: false,
                autoHide: false,
                width: 130,
                overflow: 'visible',
                textAlign: 'center',
                template: function (record, index, datatable) {

                    let template = `<div><div>`;

                    if (self.isGranted('Pages.Administration.Users.Edit')) {

                        if (self.isRead(record)) {
                            template +=
                                `<button class="btn btn-sm btn-icon btn-primary"
                                         disabled>
                                    <i class="fa fa-check" aria-label="${self.l('Read')}"></i>
                                </button>`;
                        }
                        else {                        
                            template +=
                                `<button class="btn btn-sm btn-icon btn-primary notification-read" 
                                         title="${self.l('SetAsRead')}"
                                         data-id="${record.id}">
                                    <i class="fa fa-circle-notch" aria-label="${self.l('Unread')}"></i>
                                </button>`;
                        }

                        template += 
                            `<button class="btn btn-sm btn-icon btn-danger notification-delete" 
                                     title="${self.l('Delete')}"
                                     data-id="${record.id}">
                                <i class="fa fa-times" aria-label="${self.l('Delete')}"></i>
                            </button>`;
                    }

                    return template;
                }
            }
        ];

        options.PrepareMap = function(data) {
           return self.formatNotifications(data);
        }
        
        self.ktDatatableOptions = options;
    }

    reloadPage(): void {
        this.ktDatatable.ktDatatable.reload();
    }

    setAsRead(record: any): void {
        this.setNotificationAsRead(record, () => {
            this.reloadPage();
        });
    }

    isRead(record: any): boolean {
        return record.formattedNotification.state === 'READ';
    }

    fromNow(date: moment.Moment): string {
        return moment(date).fromNow();
    }

    formatRecord(record: any): IFormattedUserNotification {
        return this._userNotificationHelper.format(record, false);
    }

    formatNotification(record: any): string {
        const formattedRecord = this.formatRecord(record);
        return abp.utils.truncateStringWithPostfix(formattedRecord.text, 120);
    }

    formatNotifications(records: any[]): any[] {
        const formattedRecords = [];
        for (const record of records) {
            record.formattedNotification = this.formatRecord(record);
            formattedRecords.push(record);
        }
        return formattedRecords;
    }

    truncateString(text: any, length: number): string {
        return abp.utils.truncateStringWithPostfix(text, length);
    }

    getNotifications(): void {
        this.ktDatatable.ktDatatable.reload();
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead(() => {
            this.getNotifications();
        });
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: UserNotification, callback: () => void): void {
        this._userNotificationHelper
            .setAsRead(userNotification.id, () => {
                if (callback) {
                    callback();
                }
            });
    }

    deleteNotification(userNotification: UserNotification): void {
        this.message.confirm(
            this.l('NotificationDeleteWarningMessage'),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this._notificationService.deleteNotification(userNotification.id)
                        .subscribe(() => {
                            this.reloadPage();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            }
        );
    }

    deleteNotifications() {
        this.message.confirm(
            this.l('DeleteListedNotificationsWarningMessage'),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this._notificationService.deleteAllUserNotifications(
                        this.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread,
                        moment(this.dateRange[0]),
                        moment(this.dateRange[1]).endOf('day')).subscribe(() => {
                            this.reloadPage();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            }
        );
    }

    public getRowClass(formattedRecord: IFormattedUserNotification): string {
        return formattedRecord.state === 'READ' ? 'notification-read' : '';
    }
}
