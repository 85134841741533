import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { CertificatesServiceProxy, EventsServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'certificateModal',
    templateUrl: './certificate-modal.component.html'
})
export class CertificateModalComponent extends AppComponentBase {

    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('certificateModal', {static: true}) modal: ModalDirective;

    public active = false;    
    public saving = false;

    userName: string;
    eventName: string;
    eventId: number;
    registrationId: number;

    certificateTypes = [];
    filterCertificateType = undefined;
    
    constructor(
        injector: Injector,
        private _eventService: EventsServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _certificateService: CertificatesServiceProxy,
        private appSessionService: AppSessionService
        
    ) {
        super(injector);
    }

    show(userName: string, eventName: string, eventId: number, registrationId: number): void {       
        this.userName = userName;
        this.eventName = eventName;
        this.eventId = eventId;
        this.registrationId = registrationId;

        this.loadCertificateTypes();
    }

    close(): void {
        this.active = false;
        this.userName = undefined;
        this.eventName = undefined;
        this.eventId = undefined;
        this.registrationId = undefined;
        this.filterCertificateType = undefined;
        this.modal.hide();
        this.modalClose.emit()
        this.closeModal();
    }

    save(): void {

        this.saving = true;
    
        this._certificateService.getCertificate(
            this.userName,
            this.registrationId,
            this.filterCertificateType.id,
            this.appSessionService.tenant.id
        )
        .pipe(finalize(() => { this.saving = false; }))
        .subscribe(result => {          
            this.notify.success(this.l('SavedSuccessfully'));            
            this._fileDownloadService.downloadTempFile(result);
            this.close();
        });
    }

    loadCertificateTypes() {
        this._eventService.getCertificateConfigs(this.eventId)
            .subscribe(result => {
            this.certificateTypes = result;

            if (this.certificateTypes.length = 1) {
                this.filterCertificateType = this.certificateTypes[0];
                this.save();
            }
            else {
                this.active = true;
                this.modal.show();
            }
        });
    }
}
