import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PaymentMethodPermissionDto, PaymentType } from '@shared/service-proxies/service-proxies';
import { createNumberMask } from 'text-mask-addons';

@Component({
  moduleId: module.id,
  selector: 'gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.less'],
  exportAs: 'gatewayComponent'
})

export class GatewayComponent extends AppComponentBase implements OnInit {

  @Input("paymentMethod") paymentMethod: PaymentMethodPermissionDto
  @Input("paymentMethodType") paymentMethodType: PaymentType;
  @Output() changeValue: EventEmitter<any> = new EventEmitter<PaymentMethodPermissionDto>();

  paymentType = PaymentType;
  numberMask2 = createNumberMask({
    prefix: '',
    integerLimit: 2,
    thousandsSeparatorSymbol: '',
    allowDecimal: false
  });
  
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  addPaymentMethod(paymentMethod: PaymentMethodPermissionDto): void {
    this.paymentMethod = paymentMethod;
    this.paymentMethodChanged();
  }

  deletePaymentMethod(): void {
    this.paymentMethod = undefined;
    this.paymentMethodChanged();
  }
  
  checkDiscountPaymentMethod(): void {
    if (this.paymentMethod && this.paymentMethod.discountPercentage.toString() == "")
      this.paymentMethod.discountPercentage = 0;
    
    this.paymentMethodChanged();
  }

  paymentMethodChanged(): void {
    this.changeValue.emit(this.paymentMethod);
  }
}