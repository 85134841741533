
import { Injectable, OnInit } from '@angular/core';
import { WidgetViewDefinition, WidgetFilterViewDefinition } from './definitions';
import { DashboardCustomizationConst } from './DashboardCustomizationConsts';
import { WidgetDailySalesComponent } from './widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetMemberActivityComponent } from './widgets/widget-member-activity/widget-member-activity.component';
import { WidgetIncomeStatisticsComponent } from './widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetRecentTenantsComponent } from './widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetEditionStatisticsComponent } from './widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetHostTopStatsComponent } from './widgets/widget-host-top-stats/widget-host-top-stats.component';
import { FilterDateRangePickerComponent } from './filters/filter-date-range-picker/filter-date-range-picker.component';
import { FilterEventComponent } from './filters/filter-event/filter-event.component';
import { WidgetMyRegistrationsComponent } from './widgets/widget-my-registrations/widget-my-registrations.component';
import { WidgetMyAnnuitiesComponent } from './widgets/widget-my-annuities/widget-my-annuities.component';
import { WidgetMyDocumentationsComponent } from './widgets/widget-my-documentations/widget-my-documentations.component';
import { WidgetMyNextEventsComponent } from './widgets/widget-my-next-events/widget-my-next-events.component';
import { WidgetRegistrationProfitShareComponent } from './widgets/widget-registration-profit-share/widget-registration-profit-share.component';
import { WidgetAnnuityProfitShareComponent } from './widgets/widget-annuity-profit-share/widget-annuity-profit-share.component';
import { WidgetRegistrationGeneralStatsComponent } from './widgets/widget-registration-general-stats/widget-registration-general-stats.component';
import { WidgetAnnuityGeneralStatsComponent } from './widgets/widget-annuity-general-stats/widget-annuity-general-stats.component';
import { WidgetRegistrationSalesSummaryComponent } from './widgets/widget-registration-sales-summary/widget-registration-sales-summary.component';
import { WidgetAnnuitySalesSummaryComponent } from './widgets/widget-annuity-sales-summary/widget-annuity-sales-summary.component';
import { WidgetRegistrationRegionalStatsComponent } from './widgets/widget-registration-regional-stats/widget-registration-regional-stats.component';
import { WidgetAnnuityRegionalStatsComponent } from './widgets/widget-annuity-regional-stats/widget-annuity-regional-stats.component';
import { WidgetGeneralDocumentsApproveComponent } from './widgets/widget-general-documents-approve/widget-general-documents-approve.component';
import { WidgetGeneralTotalProfitComponent } from './widgets/widget-general-total-profit/widget-general-total-profit.component';
import { WidgetGeneralTotalReceivablesComponent } from './widgets/widget-general-total-receivables/widget-general-total-receivables.component';
import { WidgetAnnuityTotalProfitComponent } from './widgets/widget-annuity-total-profit/widget-annuity-total-profit.component';
import { WidgetAnnuityTotalReceivablesComponent } from './widgets/widget-annuity-total-receivables/widget-annuity-total-receivables.component';
import { WidgetAnnuityNewAnnuitiesComponent } from './widgets/widget-annuity-new-annuities/widget-annuity-new-annuities.component';
import { WidgetAnnuityNewReadmissionsComponent } from './widgets/widget-annuity-new-readmissions/widget-annuity-new-readmissions.component';
import { WidgetRegistrationTotalReceivablesComponent } from './widgets/widget-registration-total-receivables/widget-registration-total-receivables.component';
import { WidgetRegistrationTotalProfitComponent } from './widgets/widget-registration-total-profit/widget-registration-total-profit.component';
import { WidgetRegistrationNewRegistrationsComponent } from './widgets/widget-registration-new-registrations/widget-registration-new-registrations.component';
import { WidgetGeneralNewMembersComponent } from './widgets/widget-general-new-members/widget-general-new-members.component';
import { WidgetRegistrationApprovalComponent } from './widgets/widget-registration-approval/widget-registration-approval.component';
import { WidgetGeneralDocumentsApproveCounterComponent } from './widgets/widget-general-documents-approve-counter/widget-general-documents-approve-counter.component';
import { WidgetMyOrdersComponent } from './widgets/widget-my-orders/widget-my-orders.component';
import { WidgetFinancialTotalProfitComponent } from './widgets/widget-financial-total-profit/widget-financial-total-profit.component';
import { WidgetFinancialTotalReceivablesComponent } from './widgets/widget-financial-total-receivables/widget-financial-total-receivables.component';
import { WidgetFinancialGeneralStatsComponent } from './widgets/widget-financial-general-stats/widget-financial-general-stats.component';
import { WidgetFinancialProfitShareComponent } from './widgets/widget-financial-profit-share/widget-financial-profit-share.component';
import { WidgetFinancialSalesSummaryComponent } from './widgets/widget-financial-sales-summary/widget-financial-sales-summary.component';
import { WidgetFinancialRegionalStatsComponent } from './widgets/widget-financial-regional-stats/widget-financial-regional-stats.component';
import { WidgetFinancialNewOrdersComponent } from './widgets/widget-financial-new-orders/widget-financial-new-orders.component';
import { WidgetFinancialOverdueOrdersComponent } from './widgets/widget-financial-overdue-orders/widget-financial-overdue-orders.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardViewConfigurationService {
  public WidgetViewDefinitions: WidgetViewDefinition[] = [];
  public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

  constructor(
  ) {
    this.initializeConfiguration();
  }

  private initializeConfiguration() {
    let filterDateRangePicker = new WidgetFilterViewDefinition(
      DashboardCustomizationConst.filters.filterDateRangePicker,
      FilterDateRangePickerComponent
    );
    let filterEvent = new WidgetFilterViewDefinition(
      DashboardCustomizationConst.filters.filterEvent,
      FilterEventComponent//the component of filter
    );
    
     //add your filters here
    this.widgetFilterDefinitions.push(filterDateRangePicker);
    this.widgetFilterDefinitions.push(filterEvent);

    let registrationGeneralStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationGeneralStats,
      WidgetRegistrationGeneralStatsComponent,
      6,
      4
    );

    let registrationDailySales = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationDailySales,
      WidgetDailySalesComponent,
    );

    let registrationProfitShare = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationProfitShare,
      WidgetRegistrationProfitShareComponent
    );

    let registrationMemberActivity = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationMemberActivity,
      WidgetMemberActivityComponent,
    );

    let registrationRegionalStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationRegionalStats,
      WidgetRegistrationRegionalStatsComponent
    );

    let registrationSalesSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationSalesSummary,
      WidgetRegistrationSalesSummaryComponent,
    );

    let registrationTotalProfit = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationTotalProfit,
      WidgetRegistrationTotalProfitComponent,
    );

    let registrationTotalReceivables = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationTotalReceivables,
      WidgetRegistrationTotalReceivablesComponent,
    );

    let registrationApproval = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationApproval,
      WidgetRegistrationApprovalComponent,
    );

    let registrationNewRegistrations = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.registrationNewRegistrations,
      WidgetRegistrationNewRegistrationsComponent,
    );

    let financialTotalProfit = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialTotalProfit,
      WidgetFinancialTotalProfitComponent,
    );

    let financialTotalReceivables = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialTotalReceivables,
      WidgetFinancialTotalReceivablesComponent,
    );

    let financialNewOrders = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialNewOrders,
      WidgetFinancialNewOrdersComponent,
    );

    let financialOverdueOrders = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialOverdueOrders,
      WidgetFinancialOverdueOrdersComponent,
    );

    let financialRegionalStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialRegionalStats,
      WidgetFinancialRegionalStatsComponent,
    );

    let financialSalesSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialSalesSummary,
      WidgetFinancialSalesSummaryComponent,
    );    
    
    let financialProfitShare = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialProfitShare,
      WidgetFinancialProfitShareComponent
    );

    let financialGeneralStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.financialGeneralStats,
      WidgetFinancialGeneralStatsComponent
    );

    let annuityTotalProfit = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityTotalProfit,
      WidgetAnnuityTotalProfitComponent,
    );

    let annuityTotalReceivables = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityTotalReceivables,
      WidgetAnnuityTotalReceivablesComponent,
    );

    let annuityNewAnnuities = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityNewAnnuities,
      WidgetAnnuityNewAnnuitiesComponent,
    );

    let annuityNewReadmissions = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityNewReadmissions,
      WidgetAnnuityNewReadmissionsComponent,
    );

    let annuityRegionalStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityRegionalStats,
      WidgetAnnuityRegionalStatsComponent,
    );

    let annuitySalesSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuitySalesSummary,
      WidgetAnnuitySalesSummaryComponent,
    );    
    
    let annuityProfitShare = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityProfitShare,
      WidgetAnnuityProfitShareComponent
    );

    let annuityGeneralStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.annuityGeneralStats,
      WidgetAnnuityGeneralStatsComponent
    );

    let generalDocumentsApprove = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.generalDocumentsApprove,
      WidgetGeneralDocumentsApproveComponent,
    );

    let generalDocumentsApproveCounter = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.generalDocumentsApproveCounter,
      WidgetGeneralDocumentsApproveCounterComponent,
    );

    let generalTotalProfit = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.generalTotalProfit,
      WidgetGeneralTotalProfitComponent,
    );

    let generalTotalReceivables = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.generalTotalReceivables,
      WidgetGeneralTotalReceivablesComponent,
    );

    let generalNewMembersComponent = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.generalNewMembersComponent,
      WidgetGeneralNewMembersComponent,
    );

    let myDocumentations = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.myDocumentations,
      WidgetMyDocumentationsComponent,
    );

    let myAnnuities = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.myAnnuities,
      WidgetMyAnnuitiesComponent,
    );

    let myNextEvents = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.myNextEvents,
      WidgetMyNextEventsComponent,
    );

    let myRegistrations = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.myRegistrations,
      WidgetMyRegistrationsComponent,
    );

    let myOrders = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.myOrders,
      WidgetMyOrdersComponent,
    );

    //add your tenant side widgets here

    let incomeStatistics = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.incomeStatistics,
      WidgetIncomeStatisticsComponent,
    );

    let editionStatistics = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.editionStatistics,
      WidgetEditionStatisticsComponent,
    );

    let recentTenants = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.recentTenants,
      WidgetRecentTenantsComponent,
    );

    let subscriptionExpiringTenants = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
      WidgetSubscriptionExpiringTenantsComponent
    );

    let hostTopStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.topStats,
      WidgetHostTopStatsComponent,
    );
    //add your host side widgets here
    
    this.WidgetViewDefinitions.push(incomeStatistics);
    this.WidgetViewDefinitions.push(editionStatistics);
    this.WidgetViewDefinitions.push(recentTenants);
    this.WidgetViewDefinitions.push(subscriptionExpiringTenants);
    this.WidgetViewDefinitions.push(hostTopStats);

    this.WidgetViewDefinitions.push(registrationTotalProfit);
    this.WidgetViewDefinitions.push(registrationTotalReceivables);
    this.WidgetViewDefinitions.push(registrationNewRegistrations);
    this.WidgetViewDefinitions.push(registrationApproval);
    this.WidgetViewDefinitions.push(registrationGeneralStats);
    this.WidgetViewDefinitions.push(registrationDailySales);
    this.WidgetViewDefinitions.push(registrationProfitShare);
    this.WidgetViewDefinitions.push(registrationMemberActivity);
    this.WidgetViewDefinitions.push(registrationRegionalStats);
    this.WidgetViewDefinitions.push(registrationSalesSummary);
        
    this.WidgetViewDefinitions.push(annuityTotalProfit);
    this.WidgetViewDefinitions.push(annuityTotalReceivables);
    this.WidgetViewDefinitions.push(annuityNewAnnuities);
    this.WidgetViewDefinitions.push(annuityNewReadmissions);
    this.WidgetViewDefinitions.push(annuitySalesSummary);
    this.WidgetViewDefinitions.push(annuityRegionalStats);
    this.WidgetViewDefinitions.push(annuityProfitShare);
    this.WidgetViewDefinitions.push(annuityGeneralStats);

    this.WidgetViewDefinitions.push(financialTotalProfit);
    this.WidgetViewDefinitions.push(financialTotalReceivables);
    this.WidgetViewDefinitions.push(financialNewOrders);
    this.WidgetViewDefinitions.push(financialOverdueOrders);
    this.WidgetViewDefinitions.push(financialSalesSummary);
    this.WidgetViewDefinitions.push(financialRegionalStats);
    this.WidgetViewDefinitions.push(financialProfitShare);
    this.WidgetViewDefinitions.push(financialGeneralStats);

    this.WidgetViewDefinitions.push(generalDocumentsApprove);
    this.WidgetViewDefinitions.push(generalDocumentsApproveCounter);
    this.WidgetViewDefinitions.push(generalTotalProfit);
    this.WidgetViewDefinitions.push(generalTotalReceivables);
    this.WidgetViewDefinitions.push(generalNewMembersComponent);

    this.WidgetViewDefinitions.push(myDocumentations)    
    this.WidgetViewDefinitions.push(myAnnuities)
    this.WidgetViewDefinitions.push(myNextEvents);
    this.WidgetViewDefinitions.push(myRegistrations);
    this.WidgetViewDefinitions.push(myOrders);
  }
}
