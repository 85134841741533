import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { CreateOrEditDocumentationDto, DocumentApprove, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';

class ApproveDocumentsList extends DashboardChartBase {

  documentsApprove: Array<any>;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getGeneralDocumentsApproveList()
      .subscribe(result => {
        this.documentsApprove = result.documentsApprove;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-general-documents-approve',
  templateUrl: './widget-general-documents-approve.component.html',
  styleUrls: ['./widget-general-documents-approve.component.css']
})
export class WidgetGeneralDocumentsApproveComponent extends WidgetComponentBase implements OnInit {

  approveDocumentsList: ApproveDocumentsList;
  documentation: CreateOrEditDocumentationDto;
  memberId: number;

  constructor(injector: Injector,
    private _router: Router,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.approveDocumentsList = new ApproveDocumentsList(this._dashboardService);
  }

  ngOnInit() {
    this.reloadDocumentations()
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.approveDocumentsList.init();
  }  

  goToAllMembers() {
    this._router.navigate(['app/admin/members/true']);
  }

  approval(document: DocumentApprove): void {
    abp.event.trigger('app.show.approve.documentation', document.memberId);
  }

  reloadDocumentations() {
    abp.event.on('app.save.approve.documentation', this.reloadTab);
  }
}