import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy, DailyRegistrationData } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class DailySalesLineChart extends DashboardChartBase {

  showMessage: boolean;

  chartData: any[];
  scheme: any = {
    name: 'green',
    selectable: true,
    group: 'Ordinal',
    domain: [
      '#34bfa3'
    ]
  };

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(data: DailyRegistrationData[]) {
    this.chartData = [];
    for (let i = 0; i < data.length; i++) {
      this.chartData.push({
        name: data[i].day,
        value: data[i].count
      });
    }
  }

  reload = (startDate: any, endDate: any, eventId: number) => {
    this.showLoading();
    this._dashboardService
      .getDailySales(startDate, endDate, eventId)
      .subscribe(result => {
        this.init(result.dailySales);
        this.showMessage = true;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-daily-sales',
  templateUrl: './widget-daily-sales.component.html',
  styleUrls: ['./widget-daily-sales.component.css']
})
export class WidgetDailySalesComponent extends WidgetComponentBase implements OnInit {
  
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterEvent: number = undefined;

  dailySalesLineChart: DailySalesLineChart;

  constructor(injector: Injector,
    private _tenantdashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.dailySalesLineChart = new DailySalesLineChart(this._tenantdashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subEventFilter();
    this.runDelayed(() =>{
      this.reloadChart();
    })
  }

  reloadChart = () => {
    this.dailySalesLineChart.reload(this.selectedDateRange[0], this.selectedDateRange[1], this.filterEvent);
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.reloadChart);
  }

  onEventFilterChange = (eventId: number) => {
    this.filterEvent = eventId;
    this.runDelayed(this.reloadChart);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subEventFilter() {
    abp.event.on('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  unSubEventFilter() {
    abp.event.off('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubEventFilter();
  }


}
