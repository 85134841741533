import { Component, Output, EventEmitter, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { WidgetOutput } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'add-widget-modal',
  templateUrl: './add-widget-modal.component.html',
  styleUrls: ['./add-widget-modal.component.css']
})
export class AddWidgetModalComponent extends AppComponentBase {

  @Output() onClose = new EventEmitter();
  @ViewChild('addWidgetModal', { static: true }) modal: ModalDirective;

  widgets: WidgetOutput[];
  saving = false;
  selectedWidgetId: string;

  constructor(
    injector: Injector) {
    super(injector);
  }

  close(): void {
    this.onClose.emit();
    this.hide();
    this.closeModal();
  }

  save(): void {
    this.onClose.emit(this.selectedWidgetId);
    this.hide();
  }

  show(widgets: WidgetOutput[]): void {

    let _widgets =  [];

    widgets.forEach(widget => {

      if (widget.description == 'Financeiro') {
        if (this.isGranted('Pages.Financial.Tenant.Orders.Management'))
        _widgets.push(widget);
      }
      else if (widget.description == 'Anuidades') {
        if (this.isGranted('Pages.Annuity.Tenant.Annuities.Management') && this.feature.isEnabled('App.AnnuitiesFeature'))
        _widgets.push(widget);
      }
      else if (widget.description == 'Inscrições') {
        if (this.isGranted('Pages.Event.Tenant.Events.Management') && this.feature.isEnabled('App.EventsFeature'))
        _widgets.push(widget);        
      }
      else {
        _widgets.push(widget);
      }      
    });

    this.widgets = _widgets;

    if (this.widgets && this.widgets.length) {
      this.selectedWidgetId = this.widgets[0].id;
    } else {
      this.selectedWidgetId = null;
    }

    this.modal.show();
  }

  hide(): void {
    this.modal.hide();
    this.closeModal();
  }
}
