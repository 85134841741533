import { Component, Input, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PhoneType } from '@shared/service-proxies/service-proxies';

@Component({
  moduleId: module.id,
  selector: 'phones',
  templateUrl: './phones.component.html',
  exportAs: 'phonesComponent'
})

export class PhonesComponent extends AppComponentBase implements OnInit {

  @Input("phones") _phones;
  @Input("phoneDto") PhoneDto;
  @Input("canDelete") canDelete;
  @ViewChild('phonesForm', { static: false }) public phonesForm: NgForm;

  phoneType = PhoneType;

  public phones = [];
  countName = 0;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this._phones.forEach(phone => {
      phone.id = this.countName--;
    });
    this.phones = this._phones;
  }

  deletePhone(index: number): void {
    this.phones.splice(index, 1);
    this.phonesForm.form.markAsDirty();
  };

  addPhone(): void {

    let phone = new this.PhoneDto();    
    phone.id = this.countName--;
    phone.ddi = '55';

    this.phones.push(phone);
  };  
}
