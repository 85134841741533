import { AppComponentBase } from "@shared/common/app-component-base";
import { OnInit, Input, ViewChild, Injector, Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CorreiosService } from "../correios/correios.service";
import { AddressType } from "@shared/service-proxies/service-proxies";
import { map } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.less'],
    exportAs: 'addressComponent'
  })
  export class AddressComponent extends AppComponentBase implements OnInit {
  
    addressType = AddressType
  
    @Input("address") _address: any[];
    @Input("addressDto") AddressDto;
    @Input("isBusiness") _isBusiness: any;
    @Input("isWizard") isWizard;
    @ViewChild('addressForm', { static: true }) addressForm: NgForm;
  
    public address: any;
    public isBusiness: any;
    public brazilStates: any[] = CorreiosService.loadBrazilStates();
    public countries: any[] = CorreiosService.loadCountries();
    public citiesFromState: any[] = [];
  
    constructor(
      private _correiosService: CorreiosService,
      injector: Injector
    ) {
      super(injector);
    }
  
    ngOnInit() {
      this.address = this._address;
      this.isBusiness = this._isBusiness
      this.loadCities();
      this.setUppercaseAddress();
    }

    setUppercaseAddress() {
      if (this.address) {
        this.address.city = this.address.city ? this.address.city.toUpperCase() : this.address.city;
        this.address.complement = this.address.complement ? this.address.complement.toUpperCase() : this.address.complement;
        this.address.district = this.address.district? this.address.district.toUpperCase() : this.address.district;
        this.address.logradour = this.address.logradour ? this.address.logradour.toUpperCase() : this.address.logradour;
        this.address.stateAbbreviation = this.address.stateAbbreviation ? this.address.stateAbbreviation.toUpperCase() : this.address.stateAbbreviation;
        this.address.stateName = this.address.stateName ? this.address.stateName.toUpperCase() : this.address.stateName;
      }
    }
  
    loadCities() {
  
      let stateAbbreviation = this.address.stateAbbreviation;
  
      if (this.isBrasil()) {
        this._correiosService.loadCities(stateAbbreviation)
          .pipe(map(cities => {

            cities.forEach(city => {
              city.Municipio = city.Municipio.toUpperCase();
            });

            this.citiesFromState = cities;
            this.setValueStateName();
          }))
          .subscribe();
      }
      else
        this.address.stateName = stateAbbreviation;
    }
  
    clearStateAndCity() {
      this.address.stateName = "";
      this.address.stateAbbreviation = "";
      this.address.city = "";
      this.address.zipCode = "";
    }
  
    setValueStateName() {
      this.brazilStates.forEach(state => {
        if (state.abbreviation == this.address.stateAbbreviation) {
          this.address.stateName = state.name.toUpperCase();
        }
      });
    }
  
    searchZipCode(): any {
      
      let zipCode = this.address.zipCode;

      zipCode = zipCode ? zipCode.replace('_', '') : '';

      if (zipCode) {
        zipCode = zipCode.replace('_', '');
    
        if (zipCode && zipCode.length >= 8) {

          zipCode = zipCode.replace('-', '');

          this._correiosService.searchByPostalCode(zipCode).pipe(
            map(result => {

              let data = result[0];

              if (!!data) {
                this.address.city = data.Municipio.toUpperCase();
                this.address.complement = data.Complemento.toUpperCase();
                this.address.district = data.Bairro.toUpperCase();
                this.address.logradour = data.Logradouro.toUpperCase();
                this.address.stateAbbreviation = data.Estado.toUpperCase();
                this.address.stateName = data.Estado.toUpperCase();
                this.address.countryName = 'Brasil';
              }
      
              this.loadCities();            
            })          
          ).subscribe();
        }
      }
    };
  
    isBrasil(): boolean {
      return this.address.countryName == 'Brasil';
    }
  }
