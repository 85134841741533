import { Injectable, Injector } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";

export class IEventScheduleSelected {
    id: number;
    name: string;
    userId: number;
    tenantId!: number;
    calendarId: number;
}

@Injectable()
export class EventSchedulesHelperService extends AppComponentBase {

    userLogged = this.appSession.user;
    tenantId = this.appSession.tenantId;

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.checkEventSchedule();
    }

    checkEventSchedule(): void {

        let eventSchedule: IEventScheduleSelected = this.loadEventScheduleSelected();

        if (!this.userLogged || !eventSchedule || eventSchedule.userId != this.userLogged.id || eventSchedule.tenantId != this.tenantId) {
            this.remove();
            abp.event.trigger('app.programmingHeader.refresh');
        }        
    }

    loadEventScheduleSelected(): IEventScheduleSelected {
        return JSON.parse(localStorage.getItem('EventSchedule'));
    }

    save(eventSchedule: IEventScheduleSelected): void {        
        localStorage.setItem('EventSchedule', JSON.stringify(eventSchedule));
    }

    remove(): void {        
        localStorage.removeItem('EventSchedule');
    }
}
