import { CurrencyMaskConfig } from "ng2-currency-mask";
 
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: false,    
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    decimal: ",",
    thousands: "."
};