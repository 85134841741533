import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private feature: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _permissionCheckerService: PermissionCheckerService        
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
            new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
            new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            new AppMenuItem('UserSituations', 'Pages.Administration.UserSituations', 'flaticon-user-ok', '/app/admin/userSituations'),
            new AppMenuItem('Members', 'Pages.Administration.Tenant.Members', 'fa fa-user-md', '/app/admin/members'),
            new AppMenuItem('Categories', 'Pages.Administration.Tenant.Categories', 'fa fa-user-cog', '/app/admin/categories'),
            new AppMenuItem('MemberSpecialties', 'Pages.Administration.Tenant.MemberSpecialties', 'fa fa-briefcase-medical', '/app/admin/specialities'),
            new AppMenuItem('Companies', 'Pages.Administration.Companies', 'flaticon-business', '/app/admin/companies'),
            new AppMenuItem('Deadlines', 'Pages.Administration.Deadlines', 'flaticon-clock-1', '/app/admin/deadlines'),
            new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
            new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
            new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
            new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
            new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
            new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
            new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),                
            ]),
            new AppMenuItem('Annuities', 'Pages.Annuity.Tenant.Annuities', 'fa fa-user-clock', '', [
                new AppMenuItem('MyAnnuities', 'Pages.Annuity.Tenant.Annuities.MyAnnuities', 'flaticon-list-1', '/app/main/my-annuities'),
                new AppMenuItem('Annuities', 'Pages.Annuity.Tenant.Annuities.Management', 'flaticon-list-1', '/app/main/annuities'),
                new AppMenuItem('AnnuitySettings', 'Pages.Annuity.Tenant.AnnuitySettings', 'flaticon-settings', '/app/main/annuity-settings'),
                new AppMenuItem('GlobalRules', 'Pages.Annuity.Tenant.AnnuitySettings', 'flaticon-settings-1', '/app/main/annuity-global-rules')
            ], undefined, undefined, this.checkFeature('App.AnnuitiesFeature')),
            new AppMenuItem('Events', 'Pages.Events', 'flaticon-event-calendar-symbol', '', [
                new AppMenuItem('RegistrationSignUp', 'Pages.Event.Tenant.Registrations.MyRegistrations', 'flaticon-rocket', '/app/main/sign-up'),
                new AppMenuItem('MyRegistrations', 'Pages.Event.Tenant.Registrations.MyRegistrations', 'flaticon-interface-3', '/app/main/my-registrations'),
                new AppMenuItem('Registrations', 'Pages.Event.Tenant.Registrations.Management', 'flaticon-interface-3', '/app/main/registrations'),
                new AppMenuItem('Certificates', 'Pages.Event.Tenant.Certificates', 'flaticon2-calendar-3', '/app/main/certificates'),
                new AppMenuItem('Events', 'Pages.Event.Tenant.Events', 'flaticon-calendar-with-a-clock-time-tools', '/app/main/events')
            ], undefined, undefined, this.checkFeature('App.EventsFeature')),
            new AppMenuItem('Scientific', 'Pages.Scientific', 'flaticon2-calendar-5', '', [
                new AppMenuItem('EventSchedules', 'Pages.Scientific.Tenant.EventSchedules', 'flaticon-calendar-1', '/app/main/event-schedules'),
                new AppMenuItem('ActivityTypes', 'Pages.Scientific.Tenant.ActivityTypes', 'flaticon2-list-2', '/app/main/activity-types'),
                new AppMenuItem('CoverageAreas', 'Pages.Scientific.Tenant.CoverageAreas', 'flaticon-squares', '/app/main/coverage-areas'),
                new AppMenuItem('ThematicAreas', 'Pages.Scientific.Tenant.ThematicAreas', 'flaticon-apps', '/app/main/thematic-areas'),
                new AppMenuItem('Activities', 'Pages.Scientific.Tenant.Activities', 'flaticon2-list-3', '/app/main/activities'),
                new AppMenuItem('ActivityTemplates', 'Pages.Scientific.Tenant.ActivityTemplates', 'flaticon-file-1', '/app/main/activity-templates'),
                new AppMenuItem('Guests', 'Pages.Scientific.Tenant.Guests', 'flaticon-presentation', '/app/main/guests'),
                new AppMenuItem('Exhibitors', 'Pages.Scientific.Tenant.Exhibitors', 'far fa-map', '/app/main/exhibitors'),
            ], undefined, undefined, this.checkFeature('App.ScientificFeature')),
            new AppMenuItem('Financial', 'Pages.Financial.Tenant.Orders', 'flaticon-coins', '', [
                new AppMenuItem('MyOrders', 'Pages.Financial.Tenant.Orders.MyOrders', 'flaticon2-shopping-cart-1', '/app/main/my-orders'),
                new AppMenuItem('Orders', 'Pages.Financial.Tenant.Orders.Management', 'flaticon2-shopping-cart-1', '/app/main/orders'),
                new AppMenuItem('OrderItemProducts', 'Pages.Financial.Tenant.OrderItemProducts.Management', 'flaticon-list', '/app/main/order-items'),
                new AppMenuItem('CouponBatches', 'Pages.Financial.Tenant.CouponBatches', 'flaticon2-gift-1', '/app/main/coupon-batches'),
                new AppMenuItem('PaymentGateways', 'Pages.Financial.Tenant.PaymentMethods', 'la la-money', '/app/main/gateways'),
                new AppMenuItem('ReceiptSettings', 'Pages.Financial.Tenant.ReceiptSettings', 'flaticon-settings', '/app/main/receipt-settings')
            ]),
            new AppMenuItem('Hotsites', 'Pages.Event.Tenant.Hotsites', 'flaticon-globe', '/app/main/hotsites', undefined, undefined, undefined, this.checkFeature('App.HotsitesFeature')),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkFeature(feature) {
        return this.feature.isEnabled(feature);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
