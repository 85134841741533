import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy, RegistrationCategoryData } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class AnnuityProfitSharePieChart extends DashboardChartBase {

  showMessage: boolean;

  totalregistrationsCount: number = 0;
  chartData: any[] = [];
  scheme: any = {
    name: 'custom',
    selectable: true,
    group: 'Ordinal',
    domain: [
      '#00c5dc', '#ffb822', '#716aca', '#17BECF', '#1F77B4', '#FF7F0E', '#ABDDA4', '#17BECF', '#1F77B4', '#8C564B', '#7F7F7F'
    ]
  };

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(data: RegistrationCategoryData[]) {

    let formattedData = [];
    for (let i = 0; i < data.length; i++) {

      formattedData.push({
        'name': data[i].category,
        'value': data[i].count,
        'color': this.scheme.domain[i]
      });
    }

    this.chartData = formattedData;
  }

  reload = (startDate: any, endDate: any, filterAnnuitySettingId: number) => {
    this.showLoading();
    this._dashboardService.getAnnuitiesProfitShare(startDate, endDate, filterAnnuitySettingId).subscribe(data => {
      if(data.profitShares.length)
        this.totalregistrationsCount = data.profitShares.reduce((p, current) => p + current.count, 0);   

      this.init(data.profitShares);
      this.showMessage = true;
      this.hideLoading();
    });
  }
}

@Component({
  selector: 'app-widget-annuity-profit-share',
  templateUrl: './widget-annuity-profit-share.component.html',
  styleUrls: ['./widget-annuity-profit-share.component.css']
})
export class WidgetAnnuityProfitShareComponent extends WidgetComponentBase implements OnInit {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterAnnuitySetting: number = undefined;

  profitSharePieChart: AnnuityProfitSharePieChart;

  constructor(injector: Injector,
    private _dashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.profitSharePieChart = new AnnuityProfitSharePieChart(this._dashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subAnnuitySettingFilter();
    this.runDelayed(() => {
      this.reloadChart();
    });
  }

  reloadChart = () => {
    this.profitSharePieChart.reload(this.selectedDateRange[0], this.selectedDateRange[1], this.filterAnnuitySetting);
  }


  getPercent(value: number, valueTotal: number): string {
    let percent = value * 100 / valueTotal;
    return percent.toFixed(0);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.reloadChart);
  }

  onAnnuitySettingChange = (annuitySettingId: number) => {
    this.filterAnnuitySetting = annuitySettingId;
    this.runDelayed(this.reloadChart);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subAnnuitySettingFilter() {
    abp.event.on('app.dashboardFilters.annuitySettingFilter.onEventChange', this.onAnnuitySettingChange);
  }

  unSubAnnuitySettingFilter() {
    abp.event.off('app.dashboardFilters.annuitySettingFilter.onEventChange', this.onAnnuitySettingChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubAnnuitySettingFilter();
  }
}
