import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class DashboardTopStats extends DashboardChartBase {

  total = 0; totalCounter = 0;
  totalChange = 0; totalChangeCounter = 0;
  
  init(total, totalChange) {
    this.total = total;
    this.totalChange = totalChange;
    this.hideLoading();
  }
}

@Component({
  selector: 'app-widget-general-documents-approve-counter',
  templateUrl: './widget-general-documents-approve-counter.component.html',
  styleUrls: ['./widget-general-documents-approve-counter.component.css']
})
export class WidgetGeneralDocumentsApproveCounterComponent extends WidgetComponentBase implements OnInit {

  dashboardTopStats: DashboardTopStats;
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  
  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.dashboardTopStats = new DashboardTopStats();
  }

  ngOnInit() {
    this.reloadDocumentationsCounter();
    this.runDelayed(this.loadTopStatsData);
  }

  loadTopStatsData = () => {
    this._tenantDashboardServiceProxy.getGeneralDocumentsApproveCounter(this.selectedDateRange[0], this.selectedDateRange[1], undefined).subscribe((data) => {
      this.dashboardTopStats.init(data.total, data.totalChange);
    });
  }

  reloadDocumentationsCounter() {
    abp.event.on('app.save.approve.documentation', this.loadTopStatsData);
  }
}
