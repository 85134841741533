import { Component, Injector, ViewChild, EventEmitter, Output } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MembersServiceProxy, CreateOrEditDocumentationDto, DocumentationStatus, CreateOrEditDocumentDto, UpdateDocumentInput } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'uploadFilesModal',
    templateUrl: './upload-files-modal.component.html'
})
export class UploadFilesModalComponent extends AppComponentBase {

    @ViewChild('uploadFilesModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    documentation: CreateOrEditDocumentationDto;
    uploadUrl: string = AppConsts.remoteServiceBaseUrl + '/Member/UploadDocumentation';
    uploadedFiles = [];
    uploadedFilesRenamed = [];

    constructor(
        injector: Injector,
        private _membersServiceProxy: MembersServiceProxy
    ) {
        super(injector);
    }

    show(documentation: CreateOrEditDocumentationDto): void {
        this.documentation = documentation;
        this.active = true;
        this.modal.show();
    }

    close(): void {
        this.uploadedFiles = [];
        this.uploadedFilesRenamed = [];
        this.active = false;
        this.modal.hide();
        this.closeModal();
    }

    onUpload(event): void {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        if (event.originalEvent) {
            this.readFiles(event.originalEvent.body.result)
        }
    }

    onBeforeSend(event): void {
        event.xhr.setRequestHeader('Authorization', 'Bearer ' + abp.auth.getToken());
    }

    readFiles(files) {

        try {
            files.forEach(x => {
                let input = new UpdateDocumentInput();
                input.fileName = x.fileName;
                this.uploadedFilesRenamed.push(input);
            });
        }
        catch (e) { }
    }

    deletePendingDocument(index: number): void {
        this.uploadedFiles.splice(index, 1);
        this.uploadedFilesRenamed.splice(index, 1);
    }

    save(): void {

        this.saving = true;

        let documentationDto = cloneDeep(this.documentation);
        
        documentationDto.id = undefined;
        documentationDto.status = DocumentationStatus.WaitingForApproval;

        documentationDto.documents.forEach(x => {
            x.id = undefined;
        });

        if (this.uploadedFilesRenamed.length > 0) {
            this.uploadedFilesRenamed.forEach(x => {
                let doc = new CreateOrEditDocumentDto();
                doc.input = new UpdateDocumentInput();
                doc.input.fileName = x.fileName;
                documentationDto.documents.push(doc);
            });
        }
        
        this._membersServiceProxy.createOrEditDocumentation(documentationDto)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                abp.event.trigger('app.save.documentation');
                this.notify.success(this.l('SavedSuccessfully'));
                this.modalSave.emit(null);
                this.close();
            });        
    }
}
