// Angular
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

export interface WizardOptions {
    startStep?: number,
    clickableSteps?: boolean
}

/**
 * Wizard
 */
@Directive({
    selector: '[ktWizard]',
    exportAs: 'ktWizard'
})
export class WizardDirective implements AfterViewInit {
    // Public properties
    @Input() options: WizardOptions;
    wizard: any;

    /**
     * Directive constructor
     * @param el: ElementRef
     */
    constructor(private el: ElementRef) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.wizard = new KTWizard(this.el.nativeElement, this.options);
    }

    /**
     * Return the wizard
     */
    getWizard() {
        return this.wizard;
    }
}
