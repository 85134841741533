import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { CreateOrEditDocumentationDto, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';
import { Router } from '@angular/router';

class MyNextEventsList extends DashboardChartBase {

  events: Array<any>;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getMyNextEventsList(this.userLoggedId)
      .subscribe(result => {
        this.events = result.events;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-my-next-events',
  templateUrl: './widget-my-next-events.component.html',
  styleUrls: ['./widget-my-next-events.component.css']
})
export class WidgetMyNextEventsComponent extends WidgetComponentBase implements OnInit {

  myNextEventsList: MyNextEventsList;
  documentation: CreateOrEditDocumentationDto;
  memberId: number;

  constructor(injector: Injector,
    private _router: Router,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.myNextEventsList = new MyNextEventsList(this._dashboardService);
  }

  ngOnInit() {
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.myNextEventsList.init();
  }  

  doRegistration(event): void {
    this._router.navigate(['app/main/registration-payment/' + event.id]);
  } 

  goToAllEvents() {
    this._router.navigate(['app/main/sign-up']);
  }
}