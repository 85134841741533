import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { AnnuitySituation, CreateOrEditDocumentationDto, DocumentationStatus, GetReceiptDto, MembersServiceProxy, OrdersServiceProxy, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';

class MyAnnuitiesList extends DashboardChartBase {

  annuities: Array<any>;
  userLoggedId = abp.session.userId;

  constructor(
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super();
  }

  init() {
    this.reload();
  }

  reload() {
    this.showLoading();
    this._dashboardService
      .getMyAnnuities(this.userLoggedId)
      .subscribe(result => {
        this.annuities = result.annuities;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-my-annuities',
  templateUrl: './widget-my-annuities.component.html',
  styleUrls: ['./widget-my-annuities.component.css']
})
export class WidgetMyAnnuitiesComponent extends WidgetComponentBase implements OnInit {

  myAnnuitiesList: MyAnnuitiesList;
  annuitySituation = AnnuitySituation;
  documentation: CreateOrEditDocumentationDto;
  memberId: number;

  constructor(injector: Injector,
    private _router: Router,
    private _ordersServiceProxy: OrdersServiceProxy,
    private _membersServiceProxy: MembersServiceProxy,
    private _dashboardService: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.myAnnuitiesList = new MyAnnuitiesList(this._dashboardService);
  }

  ngOnInit() {
    this.reloadDocumentation();
    this.runDelayed(() => {
      this.reloadTab();
    });
  }
  
  reloadTab = () =>{
    this.loadMember();
    this.myAnnuitiesList.init();
  }

  goToAllAnnuities() {
    this._router.navigate(['app/main/my-annuities']);
  }

  makePayment(annuity): void {
    if (annuity.categoryRequiresDocument && annuity.documentationStatus == DocumentationStatus.WaitingForApproval) {
      this.message.warn(this.l('WaitingForApprovalDocumentationAnnuityInfoMessage'), this.l('Warning'));
    }
    else if (annuity.categoryRequiresDocument && annuity.documentationStatus != DocumentationStatus.Approved && this.memberId) {
      this.message.warn(this.l('RequiresDocumentationAnnuityInfoMessage'), this.l('Warning'));
      abp.event.trigger('app.show.documentation', this.documentation);
    }
    else {
      this._router.navigate(['app/main/annuity-payment/' + annuity.year]);
    }
  }

  generateReceipt(annuityId): void {

    let input = new GetReceiptDto();
    input.annuityId = annuityId;

    this._ordersServiceProxy.generateReceipt(input)
        .subscribe(result => {
            window.open(AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + result.file.fileName)
        });
  }

  loadMember() {

    let userLoggedId = abp.session.userId;
    this.documentation = new CreateOrEditDocumentationDto();
    this.documentation.documents = [];    

    this._membersServiceProxy.getMemberByUserIdForView(userLoggedId, undefined)
        .subscribe(result => {
          if (result.member) {
            this.memberId = result.member.id;
            this.loadDocumentations();
          }
        });
  }

  loadDocumentations() {
    this._membersServiceProxy.getDocumentationForEdit(undefined, this.memberId)
      .subscribe(result => {          
        if (!!result.id) {
          this.documentation = result;
        }
        this.documentation.memberId = this.memberId;
      }
    );
  }

  reloadDocumentation() {
    abp.event.on('app.save.documentation', this.reloadTab);
  }
}