import { Component, OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';


class DashboardTopStats extends DashboardChartBase {

  total = 0; totalCounter = 0;
  totalChange = 0; totalChangeCounter = 0;
  
  init(total, totalChange) {
    this.total = total;
    this.totalChange = totalChange;
    this.hideLoading();
  }
}

@Component({
  selector: 'app-widget-registration-approval',
  templateUrl: './widget-registration-approval.component.html',
  styleUrls: ['./widget-registration-approval.component.css']
})
export class WidgetRegistrationApprovalComponent extends WidgetComponentBase implements OnInit {

  dashboardTopStats: DashboardTopStats;
  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];
  filterEvent: number = undefined;

  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy
  ) {
    super(injector);
    this.dashboardTopStats = new DashboardTopStats();
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.subEventFilter();
    this.runDelayed(this.loadTopStatsData);
  }

  loadTopStatsData = () => {
    this._tenantDashboardServiceProxy.getRegistrationApproval(this.selectedDateRange[0], this.selectedDateRange[1], this.filterEvent).subscribe((data) => {
      this.dashboardTopStats.init(data.total, data.totalChange);
    });
  }

  onDateRangeFilterChange = (dateRange) => {
    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    this.runDelayed(this.loadTopStatsData);
  }

  onEventFilterChange = (eventId: number) => {

    this.filterEvent = eventId;
    this.runDelayed(this.loadTopStatsData);
  }

  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  subEventFilter() {
    abp.event.on('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  unSubEventFilter() {
    abp.event.off('app.dashboardFilters.eventFilter.onEventChange', this.onEventFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
    this.unSubEventFilter();
  }
}
