import { Component, Input, OnInit, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup, Form, NgForm } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  moduleId: module.id,
  selector: 'emails',
  templateUrl: './emails.component.html',
  exportAs: 'emailsComponent'
})

export class EmailsComponent extends AppComponentBase implements OnInit {

  @Input("emails") _emails;
  @Input("emailDto") EmailDto;  
  @ViewChild('emailsForm', { static: false }) public emailsForm: NgForm;

  public emails = [];
  countName = 0;

  constructor(
    injector: Injector
  ) {
    super(injector);    
  }

  ngOnInit() {    
    this._emails.forEach(email => {
      email.id = this.countName--;
    });
    this.emails = this._emails;
  }

  deleteEmail(index: number): void {
    this.emails.splice(index, 1);
    this.emailsForm.form.markAsDirty();
  };

  addEmail(): void {

    let email = new this.EmailDto();    
    email.id = this.countName--;

    this.emails.push(email);
  };  
}
