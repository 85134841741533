import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-date-range-picker',
  templateUrl: './filter-date-range-picker.component.html',
  styleUrls: ['./filter-date-range-picker.component.css']
})
export class FilterDateRangePickerComponent extends AppComponentBase {

  date: Date;
  startDate: moment.Moment = moment().startOf('year')
  endDate: moment.Moment = moment().endOf('day');
  
  constructor(injector: Injector) {
    super(injector);
  }

  onChange() {
    let selectedDateRange: moment.Moment[] = [this.startDate, this.endDate]
    abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', selectedDateRange);
  }
}
