import { WidgetMyDocumentationsComponent } from './customizable-dashboard/widgets/widget-my-documentations/widget-my-documentations.component';
import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule, TabsModule, BsDropdownModule, BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, TooltipModule } from 'ngx-bootstrap';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { CustomizableDashboardComponent } from './customizable-dashboard/customizable-dashboard.component';
import { DashboardViewConfigurationService } from './customizable-dashboard/dashboard-view-configuration.service';
import { GridsterModule } from 'angular-gridster2';
import { WidgetDailySalesComponent } from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetEditionStatisticsComponent } from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetHostTopStatsComponent } from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import { WidgetIncomeStatisticsComponent } from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetMemberActivityComponent } from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import { WidgetRecentTenantsComponent } from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';

import { FilterDateRangePickerComponent } from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import { AddWidgetModalComponent } from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CountoModule } from 'angular2-counto';
import { KtDatatableComponent } from './kt-datatable/kt-datatable/kt-datatable.component';
import { AddressComponent } from './address/address.component';
import { AddressesComponent } from './addresses/addresses.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PhonesComponent } from './phones/phones.component';
import { EmailsComponent } from './emails/emails.component';
import { FilterEventComponent } from './customizable-dashboard/filters/filter-event/filter-event.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SocialNetworksComponent } from './social-networks/social-networks.component';
import { DateRangePickerComponent } from './timing/date-range-picker.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ActivityTopicsComponent } from './activity-topics/activity-topics.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextMaskModule } from 'angular2-text-mask';
import { ViewScheduleModalComponent } from '@app/main/guests/view-schedule-modal.component';
import { AnnuitySettingsCategoriesComponent } from './annuity-settings-categories/annuity-settings-categories';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PhoneComponent } from './phone/phone.component';
import { WidgetMyRegistrationsComponent } from './customizable-dashboard/widgets/widget-my-registrations/widget-my-registrations.component';
import { WidgetMyAnnuitiesComponent } from './customizable-dashboard/widgets/widget-my-annuities/widget-my-annuities.component';
import { WidgetMyNextEventsComponent } from './customizable-dashboard/widgets/widget-my-next-events/widget-my-next-events.component';
import { WidgetRegistrationProfitShareComponent } from './customizable-dashboard/widgets/widget-registration-profit-share/widget-registration-profit-share.component';
import { WidgetAnnuityProfitShareComponent } from './customizable-dashboard/widgets/widget-annuity-profit-share/widget-annuity-profit-share.component';
import { WidgetRegistrationGeneralStatsComponent } from './customizable-dashboard/widgets/widget-registration-general-stats/widget-registration-general-stats.component';
import { WidgetAnnuityGeneralStatsComponent } from './customizable-dashboard/widgets/widget-annuity-general-stats/widget-annuity-general-stats.component';
import { WidgetRegistrationSalesSummaryComponent } from './customizable-dashboard/widgets/widget-registration-sales-summary/widget-registration-sales-summary.component';
import { WidgetAnnuitySalesSummaryComponent } from './customizable-dashboard/widgets/widget-annuity-sales-summary/widget-annuity-sales-summary.component';
import { WidgetRegistrationRegionalStatsComponent } from './customizable-dashboard/widgets/widget-registration-regional-stats/widget-registration-regional-stats.component';
import { WidgetAnnuityRegionalStatsComponent } from './customizable-dashboard/widgets/widget-annuity-regional-stats/widget-annuity-regional-stats.component';
import { WidgetGeneralDocumentsApproveComponent } from './customizable-dashboard/widgets/widget-general-documents-approve/widget-general-documents-approve.component';
import { WidgetGeneralTotalProfitComponent } from './customizable-dashboard/widgets/widget-general-total-profit/widget-general-total-profit.component';
import { WidgetGeneralTotalReceivablesComponent } from './customizable-dashboard/widgets/widget-general-total-receivables/widget-general-total-receivables.component';
import { WidgetAnnuityTotalProfitComponent } from './customizable-dashboard/widgets/widget-annuity-total-profit/widget-annuity-total-profit.component';
import { WidgetAnnuityTotalReceivablesComponent } from './customizable-dashboard/widgets/widget-annuity-total-receivables/widget-annuity-total-receivables.component';
import { WidgetAnnuityNewAnnuitiesComponent } from './customizable-dashboard/widgets/widget-annuity-new-annuities/widget-annuity-new-annuities.component';
import { WidgetAnnuityNewReadmissionsComponent } from './customizable-dashboard/widgets/widget-annuity-new-readmissions/widget-annuity-new-readmissions.component';
import { WidgetRegistrationTotalProfitComponent } from './customizable-dashboard/widgets/widget-registration-total-profit/widget-registration-total-profit.component';
import { WidgetRegistrationTotalReceivablesComponent } from './customizable-dashboard/widgets/widget-registration-total-receivables/widget-registration-total-receivables.component';
import { WidgetRegistrationNewRegistrationsComponent } from './customizable-dashboard/widgets/widget-registration-new-registrations/widget-registration-new-registrations.component';
import { WidgetGeneralNewMembersComponent } from './customizable-dashboard/widgets/widget-general-new-members/widget-general-new-members.component';
import { WidgetRegistrationApprovalComponent } from './customizable-dashboard/widgets/widget-registration-approval/widget-registration-approval.component';
import { WidgetGeneralDocumentsApproveCounterComponent } from './customizable-dashboard/widgets/widget-general-documents-approve-counter/widget-general-documents-approve-counter.component';
import { TermModalComponent } from './term-modal/term-modal.component';
import { SelectGatewayModalComponent } from './gateway/select-gateway-modal/select-gateway-modal.component';
import { GatewayComponent } from './gateway/gateway/gateway.component';
import { WidgetMyOrdersComponent } from './customizable-dashboard/widgets/widget-my-orders/widget-my-orders.component';
import { WidgetFinancialRegionalStatsComponent } from './customizable-dashboard/widgets/widget-financial-regional-stats/widget-financial-regional-stats.component';
import { WidgetFinancialProfitShareComponent } from './customizable-dashboard/widgets/widget-financial-profit-share/widget-financial-profit-share.component';
import { WidgetFinancialGeneralStatsComponent } from './customizable-dashboard/widgets/widget-financial-general-stats/widget-financial-general-stats.component';
import { WidgetFinancialTotalProfitComponent } from './customizable-dashboard/widgets/widget-financial-total-profit/widget-financial-total-profit.component';
import { WidgetFinancialSalesSummaryComponent } from './customizable-dashboard/widgets/widget-financial-sales-summary/widget-financial-sales-summary.component';
import { WidgetFinancialTotalReceivablesComponent } from './customizable-dashboard/widgets/widget-financial-total-receivables/widget-financial-total-receivables.component';
import { WidgetFinancialNewOrdersComponent } from './customizable-dashboard/widgets/widget-financial-new-orders/widget-financial-new-orders.component';
import { WidgetFinancialOverdueOrdersComponent } from './customizable-dashboard/widgets/widget-financial-overdue-orders/widget-financial-overdue-orders.component';
import { UserMainDataComponent } from './user/user-main-data/user-main-data.component';
import { EditUserModalComponent } from './user/edit-user-modal/edit-user-modal.component';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxChartsModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        InputMaskModule,
        AutoCompleteModule,
        MatAutocompleteModule,
        DragDropModule,
        TextMaskModule,
        TooltipModule,
        CurrencyMaskModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        WidgetRegistrationGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetRegistrationProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegistrationRegionalStatsComponent,
        WidgetRegistrationSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetMyDocumentationsComponent,
        WidgetMyAnnuitiesComponent,
        WidgetMyNextEventsComponent,
        WidgetMyRegistrationsComponent,
        WidgetMyOrdersComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        KtDatatableComponent,
        AddressComponent,
        AddressesComponent,
        PhoneComponent,
        PhonesComponent,
        EmailsComponent,
        SocialNetworksComponent,
        FilterEventComponent,
        DateRangePickerComponent,
        ActivityTopicsComponent,
        ViewScheduleModalComponent,
        AnnuitySettingsCategoriesComponent,
        WidgetGeneralDocumentsApproveComponent,
        WidgetGeneralTotalProfitComponent,
        WidgetGeneralTotalReceivablesComponent,
        WidgetAnnuityProfitShareComponent,
        WidgetAnnuityGeneralStatsComponent,
        WidgetAnnuitySalesSummaryComponent,
        WidgetAnnuityRegionalStatsComponent,
        WidgetAnnuityTotalProfitComponent,
        WidgetAnnuityTotalReceivablesComponent,
        WidgetAnnuityNewAnnuitiesComponent,
        WidgetAnnuityNewReadmissionsComponent,
        WidgetFinancialRegionalStatsComponent,
        WidgetFinancialProfitShareComponent,
        WidgetFinancialGeneralStatsComponent,
        WidgetFinancialSalesSummaryComponent,
        WidgetFinancialTotalProfitComponent,
        WidgetFinancialTotalReceivablesComponent,
        WidgetFinancialNewOrdersComponent,
        WidgetFinancialOverdueOrdersComponent,
        WidgetRegistrationTotalProfitComponent,
        WidgetRegistrationTotalReceivablesComponent,
        WidgetRegistrationNewRegistrationsComponent,
        WidgetGeneralNewMembersComponent,
        WidgetRegistrationApprovalComponent,
        WidgetGeneralDocumentsApproveCounterComponent,
        TermModalComponent,
        GatewayComponent,
        SelectGatewayModalComponent,
        UserMainDataComponent,
        EditUserModalComponent

    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        NgxChartsModule,
        KtDatatableComponent,
        AddressComponent,
        AddressesComponent,
        PhoneComponent,
        PhonesComponent,
        EmailsComponent,
        SocialNetworksComponent,
        AutoCompleteModule,
        DateRangePickerComponent,
        ActivityTopicsComponent,
        ViewScheduleModalComponent,
        AnnuitySettingsCategoriesComponent,
        TermModalComponent,
        GatewayComponent,
        UserMainDataComponent,
        EditUserModalComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DashboardViewConfigurationService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig }
    ],

    entryComponents: [
        FilterDateRangePickerComponent,
        FilterEventComponent,
        WidgetRegistrationGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetRegistrationProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegistrationRegionalStatsComponent,
        WidgetRegistrationSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetMyDocumentationsComponent,
        WidgetMyAnnuitiesComponent,
        WidgetMyNextEventsComponent,
        WidgetMyRegistrationsComponent,
        WidgetMyOrdersComponent,
        WidgetGeneralDocumentsApproveComponent,
        WidgetGeneralTotalProfitComponent,
        WidgetGeneralTotalReceivablesComponent,
        WidgetAnnuityRegionalStatsComponent,
        WidgetAnnuityProfitShareComponent,
        WidgetAnnuityGeneralStatsComponent,
        WidgetAnnuitySalesSummaryComponent,
        WidgetAnnuityTotalProfitComponent,
        WidgetAnnuityTotalReceivablesComponent,
        WidgetAnnuityNewAnnuitiesComponent,
        WidgetAnnuityNewReadmissionsComponent,
        WidgetFinancialRegionalStatsComponent,
        WidgetFinancialProfitShareComponent,
        WidgetFinancialGeneralStatsComponent,
        WidgetFinancialSalesSummaryComponent,
        WidgetFinancialTotalProfitComponent,
        WidgetFinancialTotalReceivablesComponent,
        WidgetFinancialNewOrdersComponent,
        WidgetFinancialOverdueOrdersComponent,
        WidgetRegistrationTotalProfitComponent,
        WidgetRegistrationTotalReceivablesComponent,
        WidgetRegistrationNewRegistrationsComponent,
        WidgetGeneralNewMembersComponent,
        WidgetRegistrationApprovalComponent,
        WidgetGeneralDocumentsApproveCounterComponent,
        SelectGatewayModalComponent,
        UserMainDataComponent,
        EditUserModalComponent
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
