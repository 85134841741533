import { Component, OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { curveBasis } from 'd3-shape';
import { WidgetComponentBase } from '../widget-component-base';
import * as moment from 'moment';

class RegionalStatsTable extends DashboardChartBase {
  stats: Array<any>;
  colors = ['#00c5dc', '#f4516c', '#34bfa3', '#ffb822'];
  customColors = [
    { name: '1', value: '#00c5dc' },
    { name: '2', value: '#f4516c' },
    { name: '3', value: '#34bfa3' },
    { name: '4', value: '#ffb822' },
    { name: '5', value: '#00c5dc' },
    { name: '6', value: '#f4516c' },
    { name: '7', value: '#34bfa3' },
    { name: '8', value: '#ffb822' }
  ];

  curve: any = curveBasis;

  constructor(private _dashboardService: TenantDashboardServiceProxy) {
    super();
  }

  init(startDate: any, endDate: any) {
    this.reload(startDate, endDate);
    // this.hideLoading();
  }

  reload(startDate: any, endDate: any) {
    this.showLoading();
    this._dashboardService
      .getFinancialPaymentStats(startDate, endDate, undefined)
      .subscribe(result => {
        this.stats = result.stats;
        this.hideLoading();
      });
  }
}

@Component({
  selector: 'app-widget-financial-regional-stats',
  templateUrl: './widget-financial-regional-stats.component.html',
  styleUrls: ['./widget-financial-regional-stats.component.css']
})
export class WidgetFinancialRegionalStatsComponent extends WidgetComponentBase implements OnInit {

  selectedDateRange: moment.Moment[] = [moment().startOf('year'), moment().endOf('day')];  
  regionalStatsTable: RegionalStatsTable;

  constructor(injector: Injector,
    private _dashboardService: TenantDashboardServiceProxy) {
    super(injector);
    this.regionalStatsTable = new RegionalStatsTable(this._dashboardService);
  }

  ngOnInit() {
    this.subDateRangeFilter();
    this.runDelayed(() =>{
      this.reloadTab();
    });
  }

  reloadTab = () => {
    this.regionalStatsTable.init(this.selectedDateRange[0], this.selectedDateRange[1]);
  }

  onDateRangeFilterChange = (dateRange) => {

    if (!dateRange || dateRange.length !== 2 || (this.selectedDateRange[0] === dateRange[0] && this.selectedDateRange[1] === dateRange[1])) {
      return;
    }

    this.selectedDateRange[0] = dateRange[0];
    this.selectedDateRange[1] = dateRange[1];
    
    this.runDelayed(this.reloadTab);
  }
  
  subDateRangeFilter() {
    abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  unSubDateRangeFilter() {
    abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
  }

  ngOnDestroy(): void {
    this.unSubDateRangeFilter();
  }
}
